import { DataGrid } from '@mui/x-data-grid';
import * as React from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Button, Pagination } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
// import InfoIcon from '@mui/icons-material/Info';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import InfoIcon from '@mui/icons-material/Toc';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import SimpleUploadDialog from './UpoadFileModal';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import Div from '@jumbo/shared/Div/Div';
const ApifyClient = require('apify-client');
// const columns = [
//     {
//         field: 'id',
//         headerName: 'ID',
//         sortable: false,
//         hideable: false,
//         filterable: false,
//         flex: 100,
//         maxWidth: 100,
//         align: 'center',
//         headerAlign: 'center'
//     },
//     {
//         field: 'productImages',
//         headerName: 'Product Images',
//         sortable: false,
//         flex: 220,
//         align: 'center',
//         headerAlign: 'center',
//         // editable: true
//     },
//     {
//         field: 'productName',
//         headerName: 'Product Name',
//         sortable: false,
//         flex: 220,
//         align: 'center',
//         headerAlign: 'center',
//         // editable: true,
//     },
//     {
//         field: 'backgroundImages',
//         headerName: 'background Images',
//         sortable: false,
//         flex: 220,
//         align: 'center',
//         headerAlign: 'center',
//         editable: true,
//     },
// ];

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function AutomatedGraphics() {

    const [rows, setRows] = React.useState([]);
    const [sampleFile, setSampleFile] = React.useState([]);
    const [dynamicColumns, setDynamicColumns] = React.useState([]);
    const { hideDialog, showDialog } = useJumboDialog();
    const navigate = useNavigate();
    const { state } = useLocation();
    React.useEffect(() => {
        setSampleFile(state?.sampleFile);
        let generateColumn = []
        for (let i = 0; i < state.headers.length; i++) {
            if (state.headers[i] !== "API Key") {
                generateColumn.push({
                    field: state.headers[i],
                    headerName: state.headers[i],
                    sortable: false,
                    hideable: false,
                    filterable: false,
                    flex: 100,
                    maxWidth: 100,
                    align: 'center',
                    headerAlign: 'center'
                })
            }
        }
        // //console.log("generateColumn---", generateColumn);
        setDynamicColumns(generateColumn);
    }, [state])
    const columns = dynamicColumns;
    const handleCellEditChange = (params) => {
        const updatedRows = [...rows];
        updatedRows[params.id - 1][params.field] = params.value;
        setRows(updatedRows);
    };
    const onGraphicsBulkUpload = (fileData) => {
        // //console.log("onGraphicsBulkUpload--", fileData);
        let updatedRows = [...rows, fileData]
        setRows(updatedRows[0])
        hideDialog();
    }

    const showAddFileDialog = React.useCallback(() => {
        showDialog({
            title: "Add File",
            content: <SimpleUploadDialog fileHeaders={state?.headers} onChangeValue={(fileData) => onGraphicsBulkUpload(fileData)} />
        });
    }, [showDialog]);
    const Swal = useSwalWrapper();
    const sweetAlerts = (message) => {
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: message,
        });
    };
    const onGetInformationClick = () => {
        let data = {
            templateId: state?.templateId,
            templateName: state?.templateName,
            rows: rows
        }
        if (rows.length > 0) {
            navigate('/automated-graphics/details', { state: data })
        } else {
            sweetAlerts('Please Upload Some Data Here')
        }
    }
    return (
        <React.Fragment>
            <Div style={{ width: '100%' }}>
                <Button style={{ marginBottom: 5 }} sx={{ float: 'right' }} startIcon={<FileDownloadIcon />}
                    variant="contained" href={sampleFile}>
                    {/* variant="contained" href="/images/sampleAutomatedGraphics.csv"> */}
                    sample Csv
                </Button>
                <Button style={{ marginBottom: 5 }} sx={{ float: 'right', marginRight: 1 }} startIcon={<UploadFileIcon />}
                    variant="contained" onClick={showAddFileDialog}>
                    Bulk Upload Csv
                </Button>
                <Button style={{ marginBottom: 5 }} sx={{ float: 'right', marginRight: 1 }} startIcon={<InfoIcon />}
                    variant="contained" onClick={onGetInformationClick}>
                    Get Info.
                </Button>
            </Div>
            <div style={{ height: '100%', width: '100%' }}>
                <DataGrid
                    sx={{
                        // display: 'flex',
                        // alignItems: 'center',
                        mb: 5,
                        backgroundColor: '#ffffff',
                        boxShadow: 'rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;'
                    }}
                    pageSize={10}
                    autoPageSize={true}
                    autoHeight={true}
                    density='standard'
                    filterMode='server'
                    // hideFooter={true}
                    rows={[...rows]}
                    columns={[...columns]}
                    onCellEditCommit={handleCellEditChange} />
            </div>
        </React.Fragment>
    );
}