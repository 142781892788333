import CameraAltIcon from '@mui/icons-material/CameraAltOutlined';
import { Box, FormControl, InputLabel, MenuItem, Select, TextField, List, Alert } from "@mui/material";
import Chip from '@mui/material/Chip';
import Divider from "@mui/material/Divider"
import React, { useEffect, useState } from 'react';
import Paper from "@mui/material/Paper";
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";


const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.7),
    display: 'inline-block'
}));

const ChipDataList = ({ data,allData, checkData, studio, boxId, title, checkBox, date1, takeDetails, deleteDetails, addCardBoolean, cameraTake }) => {

    const [item, setItem] = useState("");
    let chipDataZ = data;

    const [selectStudio, setSelectStudio] = useState('');
    const [studioDate, setStudioDate] = useState('');
    const [alert, setAlert] = useState(false);
    const [duplicate, setDuplicate] = useState(false);
    const { studioList, cameraList } = useSelector(state => state.typereducer)
    const [studioListFinal, setStudioListFinal] = useState([]);
    const [cameraListFinal, setCameraListFinal] = useState([]);
    const [selectCamera, setSelectCamera] = useState('');
    const [isChange , SetIsChange] = useState(false);


    const disableOnTrue = (flag) => {
        return {
            opacity: flag ? 0.35 : 1,
            pointerEvents: flag ? 'none' : 'initial'

        }
    }


    const Swal = useSwalWrapper();
    const sweetAlerts = (chipToDelete) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true,
        }).then(result => {
            if (result.value) {
                deleteDetails(boxId, chipToDelete);
                SetIsChange(true);
            } else if (
                result.dismiss === Swal.DismissReason.cancel


            ) {}
        });
    };

    const handleDelete = (chipToDelete) => () => {

        sweetAlerts(chipToDelete);
        //     deleteDetails(boxId, chipToDelete);
        // setDuplicate(false)
    };

    const addChipItem = (event) => {
        
        if (selectStudio === '' || studioDate === '' || studioDate === undefined || selectStudio === undefined || selectCamera == undefined || selectCamera == '') {
            setAlert(true)
        }
        else {
            const message = event.target.value.trim();
            if (event.key === 'Enter' && message) {
                SetIsChange(true);
                var isDuplicate = false;
                if (allData !== undefined) {
                    allData?.map(itm => {
                        //console.log("itm==",itm);
                        if (message == itm?.label) {
                            isDuplicate = true;
                            setDuplicate(true);
                        }
                  
                    })
                }
                const newItem = Object.assign([], chipDataZ)
                if (!isDuplicate) {
                newItem.push({
                    key: Math.random().toString(16).slice(2),// id : IDcount
                    label: item,
                    flag: checkBox,
                    studio: selectStudio,
                    date: studioDate,
                    camera_name: selectCamera

                })
                setDuplicate(false)
                setItem("");

                
                takeDetails(boxId, title, newItem, checkBox)
                // setOldData(newItem)
                addCardBoolean(false);
            }

            }
        }
    }

    const studioChange = (event) => {
        setSelectStudio(event.target.value)

        const temp = Object.assign([], chipDataZ);
        const arr = temp?.map((itm) => itm ? { ...itm, studio: event.target.value } : {})
        takeDetails(boxId, title, arr, checkBox)

        // setOldData(arr)
    }

    const dateChange = (event) => {
        setStudioDate(event.target.value)

        const temp = Object.assign([], chipDataZ);
        const arr = temp?.map((itm) => itm ? { ...itm, date: event.target.value } : {})
        takeDetails(boxId, title, arr, checkBox)

        // setOldData(arr)

    }

    const cameraChange = (event) => {
        // //console.log("eventofcamera", event.target.value);
        setSelectCamera(event.target.value)

        const temp = Object.assign([], chipDataZ);
        const arr = temp?.map((itm) => itm ? { ...itm, camera_name: event.target.value } : {})
        takeDetails(boxId, title, arr, checkBox)

    }



    useEffect(() => {

        if (studioDate !== undefined && studioDate !== '' && selectCamera != undefined && selectCamera != '') {

            setAlert(false);

        }

    }, [selectStudio, selectCamera])

    useEffect(() => {
        // //console.log("studioDate - selectStudio",selectStudio);
        if (selectStudio !== '' && studioDate !== undefined && selectCamera != undefined && selectCamera != '') {
            setAlert(false);
        }

    }, [studioDate, selectCamera])




    useEffect(() => {
        setSelectStudio(studio);
        // setStudioDate(date);
    }, [studio])

    useEffect(() => {
        // setSelectStudio(studio);
        setStudioDate(date1);
    }, [date1])

    useEffect(() => {
        setSelectCamera(cameraTake);
    }, [cameraTake])

    useEffect(() => {
        setStudioListFinal(studioList);
        setCameraListFinal(cameraList)
    }, [studioList, cameraList])
    useEffect(()=>{
        if(isChange == false){
            takeDetails(boxId,title,chipDataZ,checkBox);
        }
    },[isChange])
    
//    //console.log("chipDataZ === > ",chipDataZ);
    return (
        <Paper style={disableOnTrue(checkData)}
            sx={{
                display: 'flex',
                justifyContent: 'center',
                flexWrap: 'wrap',
                listStyle: 'none',
                alignItems: 'center',
                p: 1,
                m: 0,
                width: '100%'
            }}
            component="ul"
        >

            <List
                sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>

                {chipDataZ?.map((item) => {
                    let icon = <CameraAltIcon />;
                    if (item.label != '') {
                        return (

                            <ListItem key={item.key}>
                                <Chip
                                    icon={icon}
                                    variant={"outlined"}
                                    label={item.label}
                                    onDelete={handleDelete(item)}
                                />
                            </ListItem>

                        );
                    }
                })}
                <Divider />
                {alert ? <Alert severity="error">Please Fill Studio & Date & Camera Details First!!</Alert> : null}
                {duplicate ? <Alert severity="error">Duplicate value is not allowed</Alert> : null}
                <ListItem>
                    <TextField
                        variant={"standard"}
                        label={"add take"}
                        value={item}
                        onChange={(e) => { setItem(e.target.value) }}
                        onKeyPress={addChipItem}
                        component="li"
                        sx={{
                            mx: 1
                        }}
                    />
                </ListItem>
                <Divider />
                <ListItem>
                    <Box>
                        <FormControl sx={{ minWidth: '220px' }}>
                            <InputLabel id="demo-simple-select-label">Studio</InputLabel>
                            <Select
                                labelId="studio-select-label"
                                id="studio-select"
                                // defaultValue={selectStudio}
                                value={selectStudio}
                                label="Studio"
                                onChange={studioChange}
                            >

                                {studioListFinal?.map(itm => {
                                    return (<MenuItem value={itm}>{itm}</MenuItem>
                                    )
                                })
                                }

                            </Select>
                        </FormControl>
                    </Box>
                </ListItem>
                <ListItem>
                    <Box>
                        <TextField
                            id="date"
                            label="Date"
                            type="date"
                            //  defaultValue={studioDate}
                            value={studioDate}
                            sx={{ width: 220 }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={dateChange}
                            fullWidth
                        />
                    </Box>
                </ListItem>
                <ListItem>
                    <Box>
                        <FormControl sx={{ minWidth: '220px' }}>
                            <InputLabel id="demo-simple-select-label">Camera</InputLabel>
                            <Select
                                labelId="camera-select-label"
                                id="camera-select"
                                // defaultValue={selectCamera}
                                value={selectCamera}
                                label="Camera"
                                onChange={cameraChange}
                            >
                                {
                                    cameraListFinal?.map(itm => {
                                        return (
                                            <MenuItem value={itm}>{itm}</MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Box>
                </ListItem>
            </List>
        </Paper>)

}

export default ChipDataList;