// import React, { useEffect } from "react";
import ListIcon from '@mui/icons-material/List';
import ArtTrack from '@mui/icons-material/ArtTrack'
import UploadIcon from '@mui/icons-material/Upload';
import FeedIcon from '@mui/icons-material/Feed';
import ReceiptIcon from '@mui/icons-material/Receipt';
import DateRangeIcon from '@mui/icons-material/DateRange';
// const roleCode = localStorage.getItem("roleCode");

const menus = [
    {
        label: 'Footage Automation',
        type: "section",
        role: ['PRODUCTIONMANAGER','SUPERADMIN','ASSISTANTDIRECTOR'],
        children: [
            // {

            //     uri: "/list-views/projects",
            //     label: 'sidebar.menuItem.projects',
            //     type: "nav-item",
            //     icon: <ViewListOutlinedIcon sx={{fontSize: 20}}/>
            // },
            
            {
                uri: "/footages",
                label: 'sidebar.menuItem.footages',
                type: "nav-item",
                role: ['PRODUCTIONMANAGER','SUPERADMIN','ASSISTANTDIRECTOR'],
                icon: <ListIcon sx={{ fontSize: 20 }} />,
                // role:["asdasd"]
            },
            {
                uri: "/footages/detailedView/tableView",
                label: 'Upload Batches',
                type: "nav-item",
                role: ['PRODUCTIONMANAGER','SUPERADMIN','ASSISTANTDIRECTOR'],
                icon: <UploadIcon sx={{ fontSize: 20 }} />
            },
        ]
    },
    {
        label: 'Automations',
        type: "section",
        children: [
            // {
            //     uri: "/automated-scripts",
            //     label: 'Automated Scripts',
            //     type: "nav-item",
            //     icon: <ArtTrack sx={{ fontSize: 20 }} />
            // },
            {
                uri: "/automated-scripts-templates",
                label: 'Automated Scripts',
                type: "nav-item",
                role: ['SUPERADMIN'],
                icon: <ArtTrack sx={{ fontSize: 20 }} />
            },
            // {
            //     uri: "/automated-graphics",
            //     label: 'Automated Graphics',
            //     type: "nav-item",
            //     icon: <ArtTrack sx={{ fontSize: 20 }} />
            // },
            {
                uri: "/automated-graphics-templates",
                label: 'Automated Graphics',
                type: "nav-item",
                role: ['SUPERADMIN'],
                icon: <ArtTrack sx={{ fontSize: 20 }} />
            },
            {
                uri: "/automated-vo",
                label: 'Automated VO',
                type: "nav-item",
                role: ['SUPERADMIN'],
                icon: <ArtTrack sx={{ fontSize: 20 }} />
            },
            {
                uri: "/asin-data",
                label: 'Asin Data',
                type: "nav-item",
                icon: <ArtTrack sx={{ fontSize: 20 }} />
            },
            
        ]
    },

    {
        label: 'HR',
        type: "section",
        children: [
            {
                label: 'Leaves',
                type: 'collapsible',
                icon: <DateRangeIcon sx={{fontSize: 20}}/>,
                children: [

                    {
                        uri: "/hr-employee-leave",
                        label: 'Employee Leave',
                        type: "nav-item",
                        icon: <DateRangeIcon sx={{ fontSize: 20 }} />
                    },
                    {
                        uri: "/approve-leave",
                        label: 'Approve Leave',
                        type: "nav-item",
                        icon: <DateRangeIcon sx={{ fontSize: 20 }} />
                    },

                ]
            },
            {
                label: 'Expenses',
                type: 'collapsible',
                icon: <FeedIcon sx={{fontSize: 20}}/>,
                children: [

                    {
                        uri: "/hr-office-expenses",
                        label: 'Office Expenses',
                        type: "nav-item",
                        icon: <FeedIcon sx={{ fontSize: 20 }} />
                    },
                    {
                        uri: "/approve-expense",
                        label: 'Approve Expenses',
                        type: "nav-item",
                        icon: <FeedIcon sx={{ fontSize: 20 }} />
                    },
                    {
                        uri: "/approve-expenses-finance",
                        label: '(F) Approve Expenses ',
                        type: "nav-item",
                        role: ['FINANCEMANAGER', 'HR'],
                        icon: <FeedIcon sx={{ fontSize: 20 }} />
                    },
                    {
                        uri: "/all-expense",
                        label: 'All Expenses',
                        type: "nav-item",
                        role: ['SUPERADMIN','FINANCEMANAGER', 'HR','ACCOUNTSMANAGER'],
                        icon: <FeedIcon sx={{ fontSize: 20 }} />
                    },

                ]
            },
            {
                label: ' O/P Expenses',
                type: 'collapsible',
                icon: <FeedIcon sx={{fontSize: 20}}/>,
                role: ['SUPERADMIN','HR','FINANCEMANAGER','ACCOUNTSMANAGER'],
                children: [
                    {
                        uri: "/op-expenses",
                        label: 'Operational Expenses',
                        type: "nav-item",
                        role: ['SUPERADMIN','HR','FINANCEMANAGER','ACCOUNTSMANAGER'],
                        icon: <FeedIcon sx={{ fontSize: 20 }} />
                    },
                    
                    {
                        uri: "/approve-op-expenses",
                        label: 'Approve Op Expenses',
                        type: "nav-item",
                        role: ['SUPERADMIN','HR','FINANCEMANAGER','ACCOUNTSMANAGER'],
                        icon: <FeedIcon sx={{ fontSize: 20 }} /> 
                    },
                    {
                        uri: "/vendor-metadata",
                        label: 'Vendors',
                        type: "nav-item",
                        role: ['SUPERADMIN','HR', 'FINANCEMANAGER','ACCOUNTSMANAGER'],
                        icon: <FeedIcon sx={{ fontSize: 20 }} />
                    },
                    {
            
                        uri: "/all-op-expenses",
                        label: 'All Op Expenses',
                        type: "nav-item",
                        role: ['SUPERADMIN','HR','FINANCEMANAGER','ACCOUNTSMANAGER'],
                        icon: <FeedIcon sx={{ fontSize: 20 }} /> 
                    },
                    

                ]
            },
            {
                label: ' Invoices ',
                type: 'collapsible',
                role: ['INFLUENCERMANAGER','SUPERADMIN','FINANCEMANAGER','ACCOUNTSMANAGER'],
                icon: <ReceiptIcon sx={{fontSize: 20}}/>,
                children: [
                    
                    {
                        uri: "/influencer-app-mapping",
                        label: 'Program Mapping',
                        type: "nav-item",
                        icon: <ReceiptIcon sx={{ fontSize: 20 }} />,
                        role: ['SUPERADMIN'],
                    },{
                        uri: "/invoices",
                        label: 'Invoices',
                        type: "nav-item",
                        role: ['INFLUENCERMANAGER','SUPERADMIN','FINANCEMANAGER','ACCOUNTSMANAGER'],
                        icon: <ReceiptIcon sx={{ fontSize: 20 }} />
                    }

                ]
            },
        ]
            
    },
       
]       

// Function to filter menu items based on user role
function filterMenuByUserRole(menu, userRole) {
    return menu
        .map(section => {
            const sectionHasRole = section.role && section.role.includes(userRole);

            // Filter section's children (menus)
            const filteredMenus = section.children.filter(menuItem => {
                const menuHasRole = menuItem.role && menuItem.role.includes(userRole);

                // Filter submenu items
                if (menuItem.children) {
                    const filteredSubmenus = menuItem.children.filter(submenuItem => {
                        const submenuHasRole = submenuItem.role && submenuItem.role.includes(userRole);
                        return !submenuItem.role || submenuHasRole;
                    });

                    // Include submenu items if present after filtering by role
                    menuItem.children = filteredSubmenus;
                }

                return !menuItem.role || menuHasRole || (menuItem.children && menuItem.children.length > 0);
            });

            // Include the section if it has roles or any child menu is visible
            if (!section.role || sectionHasRole || filteredMenus.length > 0) {
                const filteredSection = { ...section };
                filteredSection.children = filteredMenus;
                return filteredSection;
            }

            return null; // Exclude the section if no valid children or role at the section level
        })
        .filter(section => section !== null); // Filter out sections with no valid children
}

const Menu = (roleCode) => {
    // const roleCode = localStorage.getItem('roleCode');
    // useEffect(()=>{
    //     //console.log("roleCode--",roleCode);
    //     //console.log("menus--",menus);

    // },[localStorage.getItem('roleCode')])
    //console.log('debug roleCode',roleCode);
    const filteredMenu = filterMenuByUserRole(menus, roleCode);
    return filteredMenu;
    // return  menus?.map(section=>{
    //     if( !section.role || section.role.includes(roleCode) ){
    //         // return section.children?.map(menu=>{
    //         //     if( !menu.role || menu.role.includes(roleCode) ){
    //         //         return menu
    //         //     }
    //         // })
    //         let updatedSection = Object.assign({},section);
    //         updatedSection = updatedSection.children.filter(menu=>{
    //             if( !menu.role || menu.role.includes(roleCode) ){
    //                 return menu;
    //             }
    //         })
    //         return updatedSection;
    //        }
    //     }
    // return menus?.filter(section=>{
    //     if( !section.role || section.role.includes(roleCode) ){
    //         // return section.children?.map(menu=>{
    //         //     if( !menu.role || menu.role.includes(roleCode) ){
    //         //         return menu
    //         //     }
    //         // })
    //         // let updatedSection = Object.assign({},section);
    //         // updatedSection = updatedSection.children.filter(menu=>{
    //         //     if( !menu.role || menu.role.includes(roleCode) ){
    //         //         return menu;
    //         //     }
    //         // })
    //         return true;
    //        }
    //        return false;
    //     }
    // )
    
        //   if (!section.role || section.role.includes(roleCode)) {
        //     return (
        //         section
        //     //   section?.children?.map((item) => {
        //     //       if (!item.role || item.role.includes(roleCode)) {
        //     //         if (item.type === "collapsible") {
        //     //           return (
        //     //               item?.children?.map((subItem) => {
        //     //                 if (!subItem.role || subItem.role.includes(roleCode)) {
        //     //                   return subItem;
        //     //                 }
        //     //                 return null;
        //     //               })
        //     //           );
        //     //         }
        //     //         return (
        //     //             item
        //     //         );
        //     //       }
        //     //       return null;
        //     //     })
        //     );
        //   }
        //   return null;
        // })
    // ;
  };
  
  export default Menu;      
            // {
            //     uri: "/approved-expenses",
            //     label: 'Approved Expenses',
            //     type: "nav-item",
            //     icon: <ArtTrack sx={{ fontSize: 20 }} />
            // },
           
            
        
    

// if(roleCode === 'SUPERADMIN' || roleCode === 'FINANCEMANAGER' ){
//     menus[0]['children'].push({
//         uri: "/all-expense",
//         label: 'All Expenses',
//         type: "nav-item",
//         icon: <ArtTrack sx={{ fontSize: 20 }} />
//     },
    
   
//     )
// }

// if (roleCode === 'FINANCEMANAGER') {
//     menus[0]['children'].push(
        
//         {
//             uri: "/approve-expenses-finance",
//             label: 'Approve Expenses (Finance)',
//             type: "nav-item",
//             icon: <ArtTrack sx={{ fontSize: 20 }} />
//         },
//     );
// }
// if (roleCode === 'SUPERADMIN' || roleCode === 'HR') {
//     menus[0]['children'].push(
//         {
//             uri: "/vendor-metadata",
//             label: 'Vendors',
//             type: "nav-item",
//             icon: <ArtTrack sx={{ fontSize: 20 }} />
//         }
//     );
// }

// if (roleCode === 'INFLUENCERMANAGER' || roleCode === 'SUPERADMIN' || roleCode == 'ACCOUNTSMANAGER' || roleCode === 'FINANCEMANAGER' ) {
//     menus[0]['children'].push(
//         {
//             uri: "/invoices",
//             label: 'Invoices',
//             type: "nav-item",
//             icon: <ArtTrack sx={{ fontSize: 20 }} />
//         }
//     );
// }

// if ( roleCode === 'SUPERADMIN' || roleCode == 'HR' || roleCode === 'FINANCEMANAGER' ) {
//     menus[0]['children'].push(
//         {
//             uri: "/op-expenses",
//             label: 'Operational Expenses',
//             type: "nav-item",
//             icon: <ArtTrack sx={{ fontSize: 20 }} />
//         },
//         {

//             uri: "/all-op-expenses",
//             label: 'All Op Expenses',
//             type: "nav-item",
//             icon: <ArtTrack sx={{ fontSize: 20 }} /> 
//         },
//         {
//             uri: "/approve-op-expenses",
//             label: 'Approve Op Expenses',
//             type: "nav-item",
//             icon: <ArtTrack sx={{ fontSize: 20 }} /> 
//         }
//     );
// }

// export default menus;
