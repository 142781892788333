import * as yup from "yup";
// import {useMutation} from "react-query";
// import {contactService} from "../../../../../services/contact-services";
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import Div from "@jumbo/shared/Div";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import LoadingButton from "@mui/lab/LoadingButton";
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import subTaskService from 'app/services/subtask_service';
import { Form, Formik } from "formik";
import React from "react";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import moment from "moment";

const EmployeeLeaveForm = ({ leaveId }) => {
    const Swal = useSwalWrapper();
    const { hideDialog } = useJumboDialog();
    const { setAuthToken } = useJumboAuth();
    const [leaveData, setLeaveData] = React.useState({});
    const sweetAlerts = variant => {
        //console.log("variant", variant);
        Swal.fire({
            icon: variant,
            title: variant,
            text: variant === "success" ? 'Leave Entered Successfully!' : "Something went wrong",
        }).then(result => {
            window.location.reload();
        });
    };
    React.useEffect(() => {
        // //console.log("edit api call", leaveId);

        if (leaveId !== undefined) {
            // //console.log("leaveId edit api call");
            subTaskService.getLeaveDataById(leaveId).then((data) => {
                // //console.log("data---", data);
                setLeaveData(data?.data?.data)
            }).catch((err) => {
                if (err?.response?.status == 401) {
                    setAuthToken(null);
                }
            })
            // subTaskService.getLeaveApprovalData(offset, limit, filterString).then((data) => {
            //     setTotalCount(data?.data?.count);
            //     dispatch(employeeLeaveApprovalData(data?.data?.data))

            //   }).catch((err) => {
            //     if (err?.response?.status == 401) {
            //       setAuthToken(null);
            //     }
            //   })
        }
    }, [leaveId])
    return (
        <Formik
            enableReinitialize
            initialValues={{
                leaveType: leaveData?.type ? leaveData?.type : '',
                reason: leaveData?.reason ? leaveData?.reason : '',
                startDate: leaveData?.start_date ? moment(leaveData?.start_date).format('YYYY-MM-DD') : '',
                endDate: leaveData?.end_date ? moment(leaveData?.end_date).format('YYYY-MM-DD') : '',
                time: leaveData?.time ? leaveData?.time : ''
            }}
            // initialValues={contact?.id ? contact : initialValues}
            validationSchema={yup.object().shape({
                // card_title: Yup.string().max(255).required('Highest Priorities is required'),
            })}
            onSubmit={(values) => {
                // //console.log("onSubmit:", values);
                let payload = {
                    type: values?.leaveType,
                    status: "Requested",
                    start_date: values?.startDate,
                    end_date: values?.endDate,
                    time: values?.time,
                    reason: values?.reason,
                }
                // //console.log("onSubmit payload:", payload);
                if (leaveId !== undefined) {
                    subTaskService.updateLeaveStatus(payload, leaveId).then((data) => {
                        hideDialog()
                        sweetAlerts('success')
                    }).catch((err) => {
                        hideDialog()
                        if (err?.response?.status == 401) {
                            sweetAlerts('error')
                        }
                    })
                } else {
                    subTaskService.hrEmployeeAddLeavePostApi(payload).then((data) => {
                        hideDialog()
                        sweetAlerts('success')
                    }).catch((err) => {
                        hideDialog()
                        if (err?.response?.status == 401) {
                            sweetAlerts('error')
                        }
                    })
                }
                sweetAlerts('success')

            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                // isSubmitting,
                touched,
                values
            }) => {
                // //console.log('values------', values);
                return (
                    <Form noValidate autoComplete="off">
                        <Div
                            sx={{
                                '& .MuiTextField-root': {
                                    mb: 3
                                },
                            }}
                        >
                            <FormControl sx={{ mt: 2, width: "-webkit-fill-available" }}>
                                <InputLabel htmlFor="max-width">Leave Type</InputLabel>
                                <Select
                                    autoFocus
                                    value={values?.leaveType}
                                    onChange={(e) => {
                                        setFieldValue('leaveType', e?.target?.value);
                                    }}
                                    // onChange={(event) => setMaxWidth(event.target.value)}
                                    label="maxWidth"
                                    inputProps={{
                                        name: 'max-width',
                                        id: 'max-width',
                                    }}
                                >
                                    <MenuItem value="option"> Select option</MenuItem>
                                    <MenuItem value="Sick"> Sick </MenuItem>
                                    <MenuItem value="Personal">Personal </MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl sx={{ mt: 1, width: "-webkit-fill-available" }}>
                                <TextField
                                    fullWidth
                                    type='text'
                                    value={values?.reason}
                                    // value={cardItem.child_card_title}
                                    placeholder="reason.."
                                    label="Reason"
                                    variant="outlined"
                                    name="reason"
                                    multiline
                                    onChange={handleChange}
                                />
                            </FormControl>
                            <FormControl sx={{ width: "-webkit-fill-available" }}>
                                <TextField
                                    id="startDate"
                                    label="Start Date"
                                    type="date"
                                    // sx={{ width: 220 }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={handleChange}
                                    value={values?.startDate}
                                />
                            </FormControl>
                            <FormControl sx={{ width: "-webkit-fill-available" }}>
                                <TextField
                                    id="endDate"
                                    label="End Date"
                                    type="date"
                                    // sx={{ width: 220 }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={handleChange}
                                    value={values?.endDate}
                                />
                            </FormControl>
                            <FormControl sx={{ mb: 2, width: "-webkit-fill-available" }}>
                                <InputLabel htmlFor="max-width">Time</InputLabel>
                                <Select
                                    autoFocus
                                    value={values?.time}
                                    onChange={(e) => {
                                        setFieldValue('time', e?.target?.value);
                                    }}
                                    // onChange={(event) => setMaxWidth(event.target.value)}
                                    label="maxWidth"
                                    inputProps={{
                                        name: 'max-width',
                                        id: 'max-width',
                                    }}
                                >
                                    <MenuItem value="option"> Select option</MenuItem>
                                    <MenuItem value="1st Half"> 1st Half </MenuItem>
                                    <MenuItem value="2nd Half">2nd Half </MenuItem>
                                </Select>
                                {/* <TextField
                                    id="time"
                                    label="Time"
                                    type="time"
                                    // sx={{ width: 220 }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={handleChange}
                                    value={values?.time}
                                // onChange={dateChange}
                                /> */}
                            </FormControl>
                            <LoadingButton
                                fullWidth
                                type="submit"
                                variant="contained"
                                size="large"
                                sx={{ mb: 3 }}
                            // loading={isSubmitting || saveMutation.isLoading}
                            >Save</LoadingButton>
                        </Div>
                    </Form>
                );
            }}
        </Formik>
    );
};
EmployeeLeaveForm.defaultProps = {
    onSave: () => {
    }
};
export default EmployeeLeaveForm;
