import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import useJumboAuth from '@jumbo/hooks/useJumboAuth';
import { Button, Pagination } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { getInfluencerAppMappingData, InfluencerAppMappingDataCleanUp } from 'app/redux/actions/subTaskAction';
import subTaskService from 'app/services/subtask_service';
import moment from 'moment';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InfluencerAppMappingForm from './InfluencerAppMappingForm';
import { NavLink } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';

export default function InfluencerAppMapping() {

  const dispatch = useDispatch();
  const { setAuthToken } = useJumboAuth();
  const Swal = useSwalWrapper();
  const { influencerAppMappingData } = useSelector(state => state.typereducer);
  const [page, setPage] = React.useState(1);
  const [offset, setOffset] = React.useState(0);
  const [totalCount, setTotalCount] = React.useState(0);
  const [queryOptions, setQueryOptions] = React.useState({});
  const { hideDialog, showDialog } = useJumboDialog();
  const limit = 10;
  const [pageId, setPageId] = React.useState(1);

  var _id = pageId;
  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      sortable: false,
      hideable: false,
      filterable: false,
      flex: 100,
      maxWidth: 100,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        // //console.log("params.row---", params.row);
        return (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <span>{params.value}</span>
            <NavLink>
              <EditIcon onClick={() => showAddInfluencerDataDialog(params.row.influencerDataId)} style={{ width: '15px', height: "15px", marginLeft: "2px" }} color="action" />
            </NavLink>
            <NavLink>
              <DeleteIcon onClick={() => cancleLeaveRequest(params.row.influencerDataId)} style={{ width: '15px', height: "15px", marginLeft: "2px" }} color="action" />
            </NavLink>
          </div>
        );
      },
    },
    {
      field: 'projectName',
      headerName: 'Project Name',
      sortable: false,
      filterable: false,
      flex: 220,
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'projectManager',
      headerName: 'Project Manager',
      sortable: false,
      filterable: false,
      flex: 220,
      align: 'center',
      headerAlign: 'center'
    },
    
    // {
    //   field: 'actionRemark',
    //   headerName: 'Remark',
    //   sortable: false,
    //   filterable: false,
    //   flex: 300,
    //   align: 'center',
    //   headerAlign: 'center'
    // },
    // {
    //   field: 'leaveTime',
    //   headerName: 'Time',
    //   sortable: false,
    //   filterable: false,
    //   flex: 220,
    //   align: 'center',
    //   headerAlign: 'center'
    // },
    // {
    //   field: 'leaveStartDate',
    //   headerName: 'Start Date',
    //   filterable: false,
    //   sortable: false,
    //   flex: 300,
    //   align: 'center',
    //   headerAlign: 'center'
    // },
    // {
    //   field: 'leaveEndDate',
    //   headerName: 'End Date',
    //   filterable: false,
    //   sortable: false,
    //   flex: 300,
    //   align: 'center',
    //   headerAlign: 'center'
    // },
    {
      field: 'actionAt',
      headerName: 'Action At',
      filterable: false,
      sortable: false,
      flex: 300,
      align: 'center',
      headerAlign: 'center'
    },

  ];
  //console.log('vendor----------->',influencerAppMappingData)
  const rows = influencerAppMappingData.map((item) => {
    //console.log('item',item)
    return (
      {
        id: _id++,
        influencerDataId: item?.id,
        projectName: item?.project_name,
        projectManager: item?.project_manager,
        actionAt: item?.created_at,
        actionBy: item?.created_by
      }
    )
  })

  const handleValue = (event, value) => {
    setPage(value)
    setOffset((value - 1) * limit);
    setPageId(((value - 1) * limit) + 1);
  }
  const onFilterChange = React.useCallback((filterModel) => {

    //  updateFilterSearchDetails(filterModel);

    setQueryOptions({ filterModel: { ...filterModel } });

    if (filterModel?.items?.[0]?.value != undefined) {
      setOffset(0);
      setPage(1);
      setPageId(1);
    }

  }, []);


  var filterString;
  const sweetAlerts = variant => {
    Swal.fire({
      icon: variant,
      title: variant,
      text: variant === "success" ? 'Data Deleted Successfully!' : "Something went wrong",
    }).then(result => {
      window.location.reload();
    });
  };

  React.useEffect(() => {
    if (queryOptions?.filterModel?.items?.[0]?.columnField != undefined) {

      filterString = `&${queryOptions?.filterModel?.items?.[0]?.columnField}=${(queryOptions?.filterModel?.items?.[0]?.value) ? queryOptions?.filterModel?.items?.[0]?.value : ''}`

    } else {
      filterString = ''
    }
    subTaskService.getInfluencerAppMappingData(offset, limit, filterString).then((data) => {
      setTotalCount(data?.data?.count);
      dispatch(getInfluencerAppMappingData(data?.data?.data))

    }).catch((err) => {
      if (err?.response?.status == 401) {
        setAuthToken(null);
      }
    })
    return () => {
      dispatch(InfluencerAppMappingDataCleanUp())
    }
  }, [offset, queryOptions])
  const handleInfluencerAppMappingDataAdd = React.useCallback(() => {
    hideDialog();
    // setContactsListRefresh(true);
  }, [hideDialog]);
  const showAddInfluencerDataDialog = React.useCallback((influencerDataId) => {

    showDialog({
      title: "Add Influencer App Data",
      content: <InfluencerAppMappingForm influencerDataId={influencerDataId} onSave={handleInfluencerAppMappingDataAdd} />
    });
  }, [handleInfluencerAppMappingDataAdd, showDialog]);
  const cancleLeaveRequest = (influencerDataId) => {
    let payload = {
      status: "Cancelled",
    }
    
    subTaskService.deleteInfluencerAppMappingData(influencerDataId).then((data) => {
      //console.log('deleted')
      hideDialog()
      sweetAlerts('success')
    }).catch((err) => {
      hideDialog()
      if (err?.response?.status == 401) {
        sweetAlerts('error')
      }
    })
  }

  return (
    <>

      <div style={{ height: '60px', width: '100%' }}>
        <Button sx={{ float: 'right' }} variant="contained" onClick={() => showAddInfluencerDataDialog()}>
          Add Data
        </Button>
      </div>
      <div style={{ height: '100%', width: '100%' }}>

        <DataGrid sx={{ backgroundColor: '#F6F4FA' }}
          rows={[...rows]}
          columns={[...columns]}
          pageSize={30}
          autoPageSize={true}
          autoHeight={true}
          density='standard'
          filterMode='server'
          onFilterModelChange={onFilterChange}
          hideFooter={true}


        />
        <Pagination count={Math.ceil(totalCount / limit)} sx={{ mt: 3, float: 'right', color: "#F6F4FA" }} page={page} onChange={handleValue} />
      </div>

    </>
  );
}