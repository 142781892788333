import React, { useState } from 'react';
import UserItem from "./UserItem";
import { CircularProgress, Grid, Pagination, TextField, Typography } from "@mui/material";
import { useEffect } from 'react';
import subTaskService from 'app/services/subtask_service';
import { subtaskGetData, userListCleanUp } from 'app/redux/actions/subTaskAction';
import { useSelector, useDispatch } from 'react-redux';
import { Search, SearchIconWrapper, StyledInputBase } from "./style";
import SearchIcon from "@mui/icons-material/Search";
import Div from '@jumbo/shared/Div';
import useJumboAuth from '@jumbo/hooks/useJumboAuth';

const UsersList = () => {
    const dispatch = useDispatch();
    const {setAuthToken} = useJumboAuth();
    const typereducer = useSelector(state => state.typereducer)
    const [isLoading, setIsLoading] = useState(false);

    const roleCode = localStorage.getItem("roleCode");
    const [offset, setOffset] = React.useState(0);
    const [totalCount, setTotalCount] = React.useState(0);
    const [dateSearch, setDateSearch] = React.useState();


    const [search, setSearch] = React.useState("");

    const [page, setPage] = React.useState(1);
    const limit = 50;

    const handleValue = (event, value) => {
        setPage(value)
        setOffset((value - 1) * limit);

    }

    const dateChange = (event) => {
        // //console.log("date chnage",event.target.value);
        setDateSearch(event.target.value);
    }

    useEffect(() => {
        setIsLoading(true)
        if (dateSearch == '' || dateSearch == undefined || dateSearch == null) {

            subTaskService.userlist(roleCode, offset, search?.trim()).then((data) => {
                setTotalCount(data?.data?.count);

                dispatch(subtaskGetData(data?.data?.data));
                setIsLoading(false)
            }).catch((err) => {
                if (err?.response?.status == 401) {
                    setAuthToken(null);
                }
            })
        }
        else {
            subTaskService.userlistsearchbydate(roleCode, offset, search?.trim(), dateSearch).then((data) => {
                setTotalCount(data?.data?.count);

                dispatch(subtaskGetData(data?.data?.data));
                setIsLoading(false)
            }).catch((err) => {
                if (err?.response?.status == 401) {
                    setAuthToken(null);
                }
            })

            setIsLoading(false)
        }
        return () => {
            dispatch(userListCleanUp())
        }
    }, [offset, search, dateSearch])

    //   //console.log("typereducer?.details" , typereducer?.details)

    return (
        <React.Fragment>
            <Typography variant={'h2'} mb={3}>Footages</Typography>

            <Div sx={{}}>
                <Grid container spacing={2} mb={3}>
                    <Grid item xs={4}>
                        <Search onChange={(e) => setSearch(e.target.value)} >
                            <SearchIconWrapper>
                                <SearchIcon />
                            </SearchIconWrapper>

                            <StyledInputBase
                                placeholder="Search anything"
                                inputProps={{ 'aria-label': 'search' }}
                            />
                        </Search>
                    </Grid>

                    <Grid item xs>
                        <Pagination count={Math.ceil(totalCount / limit)} sx={{ float: 'right' }} color="secondary" page={page} onChange={handleValue} />

                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="date"
                            label="Search Filming Date"
                            type="date"
                            sx={{ width: 220 }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={dateChange}
                        />
                    </Grid>
                </Grid>
            </Div>

            {isLoading ?
                <Div sx={{ flexGrow: 1 }}><CircularProgress color='primary' sx={{ marginLeft: '50%', marginTop: '20%' }} /> </Div>

                :

                typereducer?.details.map((user, index) => (
                    <UserItem user={user} key={index} />
                ))
            }

        </React.Fragment>
    );
};

export default UsersList;
