import baseAxios from "axios";

export const axios = baseAxios.create({
   baseURL: "https://jpirxj2umf.execute-api.ap-south-1.amazonaws.com/",    //new prod
  // baseURL: "https://7wkmy756b1.execute-api.ap-south-1.amazonaws.com/",  //stagging
  headers: {
    'Content-Type': 'application/json'
  }
});
axios.interceptors.request.use(async function (config) {
  if (localStorage.getItem("x_acess_token")) {
    config.headers.x_acess_token = localStorage.getItem("x_acess_token")
  }
  return config;
});
axios.interceptors.response.use((response) => {
  // //console.log("error response", response.status);
  if (response.config.parse) {
    //perform the manipulation here and change the response object
  }
  return response;
}, (error) => {
  if (error?.response?.status == 401) {
    localStorage.removeItem("x_acess_token")
    localStorage.removeItem("user")
    localStorage.removeItem("roleCode")
  }
  return Promise.reject(error);
});




export const hraxios = baseAxios.create({
  // baseURL: "http://192.168.0.52:5000/api/v1",//Local
    baseURL: "https://api.thezikhara.com/api/v1", // PROD
  // baseURL: "https://staging-apis.thezikhara.com/api/v1", //STAGING

  headers: {
    'Content-Type': 'application/json'
  }
});
hraxios.interceptors.request.use(async function (config) {
  if (localStorage.getItem("x_acess_token")) {
    config.headers.x_acess_token = localStorage.getItem("x_acess_token")
  }
  return config;
});
hraxios.interceptors.response.use((response) => {
  //console.log("error response", response.status);
  if (response.config.parse) {
    //perform the manipulation here and change the response object
  }
  return response;
}, (error) => {
  if (error?.response?.status == 401) {
    localStorage.removeItem("x_acess_token")
    localStorage.removeItem("user")
    localStorage.removeItem("roleCode")
  }
  return Promise.reject(error);
});
export const scriptaxios = baseAxios.create({
  baseURL: "https://openai.thezikhara.com/",
  headers: {
    'Content-Type': 'application/json'
  }
});
hraxios.interceptors.request.use(async function (config) {
  if (localStorage.getItem("x_acess_token")) {
    config.headers.x_acess_token = localStorage.getItem("x_acess_token")
  }
  return config;
});
hraxios.interceptors.response.use((response) => {
  //console.log("error response", response.status);
  if (response.config.parse) {
    //perform the manipulation here and change the response object
  }
  return response;
}, (error) => {
  if (error?.response?.status == 401) {
    localStorage.removeItem("x_acess_token")
    localStorage.removeItem("user")
    localStorage.removeItem("roleCode")
  }
  return Promise.reject(error);
});


export const automatedVoaxios = baseAxios.create({
  baseURL: "https://4qmyt8eh78.execute-api.ap-south-1.amazonaws.com/dev/", //prod

  headers: {
    'Content-Type': 'application/json'
  }
});
hraxios.interceptors.request.use(async function (config) {
  if (localStorage.getItem("x_acess_token")) {
    config.headers.x_acess_token = localStorage.getItem("x_acess_token")
  }
  return config;
});
hraxios.interceptors.response.use((response) => {
  //console.log("error response", response.status);
  if (response.config.parse) {
    //perform the manipulation here and change the response object
  }
  return response;
}, (error) => {
  if (error?.response?.status == 401) {
    localStorage.removeItem("x_acess_token")
    localStorage.removeItem("user")
    localStorage.removeItem("roleCode")
  }
  return Promise.reject(error);
});


export const pianosaaxios = baseAxios.create({
  // baseURL: "http://192.168.0.47:4000/api/v1", //pianosa base url
  baseURL: "https://app-api.thezikhara.com/api/v1", //Stagging
  headers: {
    'Content-Type': 'application/json'
  }
});
hraxios.interceptors.request.use(async function (config) {
  if (localStorage.getItem("x_acess_token")) {
    config.headers.x_acess_token = localStorage.getItem("x_acess_token")
  }
  return config;
});
hraxios.interceptors.response.use((response) => {
  //console.log("error response", response.status);
  if (response.config.parse) {
    //perform the manipulation here and change the response object
  }
  return response;
}, (error) => {
  if (error?.response?.status == 401) {
    localStorage.removeItem("x_acess_token")
    localStorage.removeItem("user")
    localStorage.removeItem("roleCode")
  }
  return Promise.reject(error);
});

//todo: define interceptors and other configuration like baseURL, headers etc. here
// export default hraxios;