import React from 'react';
import Avatar from "@mui/material/Avatar";
import {Button} from "@mui/material";
import ContentHeader from "../../layouts/shared/headers/ContentHeader";
import List from "@mui/material/List";
import MenuItem from "@mui/material/MenuItem";
import SettingsIcon from '@mui/icons-material/Settings';
import styled from "@emotion/styled";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { useSelector } from 'react-redux';
// import {ASSET_AVATARS} from "../../../utils/constants/paths";
// import {getAssetPath} from "../../../utils/appHelpers";

const StyledMenuItem = styled(MenuItem)(({theme}) => ({
    padding: theme.spacing(0, 1),

    '&:hover': {
        backgroundColor: 'transparent',
    },

    '& .MuiTouchRipple-root': {
        display: 'none'
    }

}));

const Item = styled("div")({
    textAlign: 'center',
});


const Header = () => {

    const typereducer = useSelector(state => state.typereducer);
    // //console.log("typereducer",typereducer?.detailedSideInfos?.dmfTask?.[0]?.task)

    return (
        <ContentHeader
          
            children={
                <Stack
                    direction={"row"}
                    justifyContent={"space-evenly"}
                    divider={<Divider orientation="vertical" flexItem/>}
                    spacing={2}
                    sx={{
                        mx: 1
                    }}
                >
                    <Item>
                        <Typography variant={"h6"} color={'inherit'} mb={0}>{typereducer?.detailedSideInfos?.dmfTask?.[0]?.task}</Typography>
                        <Typography variant={'body1'} fontSize={12}>Task Code</Typography>
                    </Item>
                    <Item>
                        <Typography variant={"h6"} color={'inherit'} mb={0}>{typereducer?.detailedSideInfos?.dmfTask?.[0]?.video_code}</Typography>
                        <Typography variant={'body1'} fontSize={12}>Video Code</Typography>
                    </Item>
                    
                </Stack>
            }
            
           
            sx={{
                position: 'relative',
                zIndex: 1,

                '& .MuiCardHeader-action': {
                    alignSelf: 'center'
                }
            }}
        />
    );
};

export default Header;
