import { ASIN_NUMBER_GET_DATA } from "app/utils/constants/types";
import { automatedVoaxios, axios, hraxios, pianosaaxios, scriptaxios } from "./config";

const subTaskService = {};

subTaskService.userlist = async (roleCode, offset, search) => {
    const { data } = await axios.get(`footage/get-my-subtasks?roleCode=${roleCode}&limit=50&offset=${offset}&asinNumber=${search}`);
    return data;
};

subTaskService.userlistsearchbydate = async (roleCode, offset, search, date) => {
    const { data } = await axios.get(`footage/get-my-subtasks?roleCode=${roleCode}&limit=50&offset=${offset}&asinNumber=${search}&filmingStartDate=${date}&filmingEndDate=${date}`);
    return data;
};


subTaskService.subtaskdetails = async (id) => {
    const { data } = await axios.get(`footage/subtaskdetails/${id}`);
    return data;
}

subTaskService.subtaskdetailssideinfo = async (id1, id2) => {
    const { data } = await axios.get(`footage/subtaskdetails/side_info/${id1}/${id2}`);
    return data;
}

subTaskService.getmysubtaskdetails = async (id) => {
    const { data } = await axios.get(`footage/get-my-subtaskdetails/${id}`)
    return data;
}

subTaskService.subtaskDetailsInfoPostApi = async (payload) => {
    const data = await axios.post("footage/update-footage-take-details", payload)
    return data;
}


subTaskService.subTaskGetMyFootageUploadData = async (offset, limit, search) => {
    if (offset == '' && limit == undefined) {
        const data = await axios.get(`footage/get-my-footageupload-data?offset=0&limit=10000`)
        return data;
    }
    const data = await axios.get(`footage/get-my-footageupload-data?offset=${offset}&limit=${limit}${search}`)
    return data;
}

subTaskService.subTaskGetStudioAndCameraList = async (type) => {
    const data = await axios.get(`/footage/get-masterdata?type=${type}`);
    return data;
}
subTaskService.getUserByRole = async (type) => {
    const data = await hraxios.get(`/user/by_role_code/${type}`);
    return data;
}
subTaskService.checkAuthForManualTrigger = async () => {
    //have to put url of manual trigger
    const data = await axios.get(`/copy-footage`, {
        headers: {
            x_acess_token: localStorage.getItem('x_acess_token'),
        }
    })
    return data;
}
subTaskService.getHrEmployeeLeaveData = async (offset, limit, search) => {
    if (offset == '' && limit == undefined) {
        const data = await hraxios.get(`hr_employee_leave?offset=0&limit=10000`)
        return data?.data;
    }
    const data = await hraxios.get(`hr_employee_leave?offset=${offset}&limit=${limit}${search}`)
    return data?.data;
}
subTaskService.getLeaveDataById = async (id) => {
    const { data } = await hraxios.get(`hr_employee_leave/${id}`)
    return data;
}
subTaskService.hrEmployeeAddLeavePostApi = async (payload) => {
    // //console.log("hrEmployeeAddLeave", payload);
    const data = await hraxios.post("hr_employee_leave", payload)
    return payload;
}
subTaskService.getLeaveApprovalData = async (offset, limit, search) => {
    if (offset == '' && limit == undefined) {
        const data = await hraxios.get(`hr_employee_leave/approval-req?offset=0&limit=10000`)
        return data?.data;
    }
    const data = await hraxios.get(`hr_employee_leave/approval-req?offset=${offset}&limit=${limit}${search}`)
    return data?.data;
}
subTaskService.updateLeaveStatus = async (payload, leaveId) => {
    //console.log('hi',payload)
    const data = await hraxios.post(`hr_employee_leave/${leaveId}`, payload)
    return payload;
}

subTaskService.updatePaymentStatus = async (payload) => {
    //console.log('payload for payment',payload);
    const data = await hraxios.post(`hr_office_expenses/update-payment-status`, payload)
    //console.log('data payload',data);
    return payload;
}
subTaskService.getHrOfficeExpenses = async (offset, limit, search) => {
    if (offset == '' && limit == undefined) {
        const data = await hraxios.get(`hr_office_expenses?offset=0&limit=10000`)
        return data?.data;
    }
    const data = await hraxios.get(`hr_office_expenses?offset=${offset}&limit=${limit}${search}`)
    return data?.data;
}
subTaskService.addExpensePostApi = async (payload) => {
    const data = await hraxios.post("hr_office_expenses", payload)
    return payload;
}
subTaskService.getExpensesApprovals = async (page,offset, limit, search,) => {

    //console.log('roleCheck',page)
    if (offset == '' && limit == undefined) {
        //console.log('hello role');
        const data = await hraxios.get(`hr_office_expenses/expenses-approval-req?offset=0&limit=10000&page=${page}`)
        return data?.data;
    }
    const data = await hraxios.get(`hr_office_expenses/expenses-approval-req?offset=${offset}&limit=${limit}&page=${page}${search}`)
    return data?.data;
}
subTaskService.getOpExpensesApprovals = async (offset, limit, search) => {
    if (offset == '' && limit == undefined) {
        const data = await hraxios.get(`operational_expenses/op-expenses-approval-req?offset=0&limit=10000`)
        return data?.data;
    }
    const data = await hraxios.get(`operational_expenses/op-expenses-approval-req?offset=${offset}&limit=${limit}${search}`)
    return data?.data;
}
subTaskService.getAllExpensess = async (offset, limit, search) => {
    if (offset == '' && limit == undefined) {
        const data = await hraxios.get(`hr_office_expenses/get-all-expenses?offset=0&limit=10000`)
        return data?.data;
    }
    const data = await hraxios.get(`hr_office_expenses/get-all-expenses?offset=${offset}&limit=${limit}${search}`)
    return data?.data;
}
subTaskService.getAllOpExpensess = async (offset, limit, search) => {
    if (offset == '' && limit == undefined) {
        const data = await hraxios.get(`operational_expenses/get-all-op-expenses?offset=0&limit=10000`)
        return data?.data;
    }
    const data = await hraxios.get(`operational_expenses/get-all-op-expenses?offset=${offset}&limit=${limit}${search}`)
    return data?.data;
}
subTaskService.updateAllPaymentStatus = async (payload, expanseId) => {
    const data = await hraxios.post(`hr_office_expenses/${expanseId}`, payload)
    return payload;
}
subTaskService.getHrExpenseDataById = async (id) => {
    const { data } = await hraxios.get(`hr_office_expenses/${id}`)
    return data;
}
subTaskService.updateExpenseStatus = async (payload, expenseId) => {
    const data = await hraxios.post(`hr_office_expenses/${expenseId}`, payload)
    return payload;
}
subTaskService.updateOpExpensePaymentStatus = async (payload) => {
    const data = await hraxios.post(`operational_expenses/update-payment-status`, payload)
    return payload;
}
subTaskService.asinDetailDataList = async (asinNumbers, offset) => {
    const { data } = await hraxios.get(`/asin_data/?&limit=50&offset=0&asinNumbers=${asinNumbers}`);
    return data;
};
subTaskService.getAllScriptModel = async () => {
    const { data } = await scriptaxios.get(`/get-all-script-models`);
    return data;
};
subTaskService.getAutomatedVoByAsin = async (voText, fileName) => {
    const { data } = await automatedVoaxios.post(`/file_upload`, { text: voText, file_name: fileName });
    return data;
};
subTaskService.AddVendorPostApi = async (payload) => {
    // //console.log("hrEmployeeAddLeave", payload);
    const data = await hraxios.post("vendor_add_data", payload)
    return payload;
}
subTaskService.getVendorData = async (offset, limit, search) => {
    if (offset == '' && limit == undefined) {
        const data = await hraxios.get(`vendor_add_data?offset=0&limit=10000`)
        return data?.data;
    }
    const data = await hraxios.get(`vendor_add_data?offset=${offset}&limit=${limit}${search}`)
    return data?.data;
}
subTaskService.getInfluencerAppMappingData = async (offset, limit, search) => {
    //console.log('ok influencer')
    if (offset == '' && limit == undefined) {
        const data = await hraxios.get(`influencer_app_mapping?offset=0&limit=10000`)
        return data?.data;
    }
    const data = await hraxios.get(`influencer_app_mapping?offset=${offset}&limit=${limit}${search}`)
    return data?.data;
}
subTaskService.getVendorDataById = async (id) => {
    const { data } = await hraxios.get(`vendor_add_data/${id}`)
    return data;
}
subTaskService.updateVendor = async (payload, vendorId) => {
    //console.log('hi',payload)
    const data = await hraxios.post(`vendor_add_data/${vendorId}`, payload)
    return payload;
}
subTaskService.deleteVendorData = async ( vendorId) => {
    //console.log('vendorid', vendorId)
    const data = await hraxios.delete(`vendor_add_data/${vendorId}`)
   
}
subTaskService.getVendorName = async (offset, limit, search) => {
    if (offset == '' && limit == undefined) {
        const data = await hraxios.get(`vendor_add_data?offset=0&limit=10000`)
        return data?.data;
    }
    const data = await hraxios.get(`vendor_add_data?offset=${offset}&limit=${limit}${search}`)
    //console.log("data==>",data);
    return data?.data;
}
subTaskService.addOpExpensePostApi = async (payload) => {
    //console.log('op expense')
    const data = await hraxios.post("operational_expenses", payload)
    return payload;
}

subTaskService.getOperationalExpenses = async (offset, limit, search) => {
    if (offset == '' && limit == undefined) {
        const data = await hraxios.get(`operational_expenses?offset=0&limit=10000`)
        return data?.data;
    }
    const data = await hraxios.get(`operational_expenses?offset=${offset}&limit=${limit}${search}`)
    return data?.data;
}
subTaskService.getOpExpenseDataById = async (id) => {
    
    const { data } = await hraxios.get(`operational_expenses/${id}`)
    
    return data;
}
subTaskService.updateOpExpenseStatus = async (payload, expenseId) => {
    const data = await hraxios.post(`operational_expenses/${expenseId}`, payload)
    return payload;
}
subTaskService.getProjectManagerName = async (offset, limit, search) => {
    //console.log('hello program manager')
    if (offset == '' && limit == undefined) {
        const data = await hraxios.get(`user/get-all-program-manager-for-fa-system/INFLUENCERMANAGER`)
        return data?.data?.data;
    }
    const data = await hraxios.get(`user/get-all-program-manager-for-fa-system/INFLUENCERMANAGER`)
    //console.log("data==>",data?.data?.data);
    return data?.data?.data;
}

subTaskService.getProject = async (offset, limit, search) => {
    //console.log('hello program')
    if (offset == '' && limit == undefined) {
        const data = await pianosaaxios.get(`routes_for_fa/fetch-all-influencer-program`)
        return data?.data?.data;
    }
    const data = await pianosaaxios.get(`routes_for_fa/fetch-all-influencer-program`)
    //console.log("data project==>",data?.data?.data);
    return data?.data?.data;
}
subTaskService.addInfluencerAppMappingPostApi = async (payload) => {
    //console.log('hello influencer')
    const data = await hraxios.post("influencer_app_mapping", payload)
    //console.log('return influencer')

    return payload;
}
subTaskService.updateInfluencerData = async (payload, influencerDataId) => {
    //console.log('hi',payload)
    const data = await hraxios.post(`influencer_app_mapping/${influencerDataId}`, payload)
    return payload;
}

subTaskService.deleteInfluencerAppMappingData = async ( influencerDataId) => {
    //console.log('vendorid', influencerDataId)
    const data = await hraxios.delete(`influencer_app_mapping/${influencerDataId}`)
   
}
subTaskService.getInfluencerDataById = async (id) => {
    const { data } = await hraxios.get(`influencer_app_mapping/${id}`)
    return data;
}
subTaskService.getInvoiceById = async (id) => {
    //console.log('hello user',id)
    
    const { data } = await hraxios.get(`influencer_app_mapping/fetch-invoices-by-manager/${id}`)
    //console.log('user invoice data',data)
    return data;
}
subTaskService.updateInvoicePaymentStatus = async (payload) => {
    const data = await hraxios.post(`/influencer_app_mapping/invoice/update-status-of-invoice`, payload)
    return payload;
}
subTaskService.updateInvoiceStatus = async (payload) => {
    const data = await hraxios.post(`/influencer_app_mapping/invoice/update-status-of-invoice`, payload)
    return payload;
}

export default subTaskService;