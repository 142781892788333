import * as yup from "yup";
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import Div from "@jumbo/shared/Div";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import LoadingButton from "@mui/lab/LoadingButton";
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import subTaskService from 'app/services/subtask_service';
import { Form, Formik } from "formik";
import { capitalize } from "app/utils/appHelpers";

const ExpenseApprovalFrom = ({ expenseId, hrFinanceStatus }) => {
    const Swal = useSwalWrapper();
    const { hideDialog } = useJumboDialog();
    const sweetAlerts = variant => {
        Swal.fire({
            icon: variant,
            title: capitalize(variant),
            text: variant === "success" ? 'Expense Updated!' : "Something went wrong",
        }).then(result => {
            window.location.reload();
        });
    };
    return (
        <Formik
            enableReinitialize
            initialValues={{
                leaveStatus: '',
                reason: '',
            }}
            validationSchema={yup.object().shape({
                // card_title: Yup.string().max(255).required('Highest Priorities is required'),
            })}
            onSubmit={(values) => {
                // //console.log("onSubmit:", values);
                let payload = {
                    hr_finance_status: hrFinanceStatus === "Requested" ? values?.leaveStatus : "Requested",
                    status: values?.leaveStatus,
                    action_remark: values?.reason,
                }
                // //console.log("onSubmit payload:", payload);
                subTaskService.updateExpenseStatus(payload, expenseId).then((data) => {
                    hideDialog()
                    sweetAlerts('success')
                }).catch((err) => {
                    hideDialog()
                    if (err?.response?.status == 401) {
                        sweetAlerts('error')
                    }
                })
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                // isSubmitting,
                touched,
                values
            }) => {
                // //console.log('values------', values);
                return (
                    <Form noValidate autoComplete="off">
                        <Div
                            sx={{
                                '& .MuiTextField-root': {
                                    mb: 3
                                },
                            }}
                        >
                            <FormControl sx={{ mt: 2, width: "-webkit-fill-available" }}>
                                <InputLabel htmlFor="max-width">Approval</InputLabel>
                                <Select
                                    autoFocus
                                    value={values?.leaveStatus}
                                    onChange={(e) => {
                                        setFieldValue('leaveStatus', e?.target?.value);
                                    }}
                                    // onChange={(event) => setMaxWidth(event.target.value)}
                                    label="maxWidth"
                                    inputProps={{
                                        name: 'max-width',
                                        id: 'max-width',
                                    }}
                                >
                                    <MenuItem value="option"> Select option</MenuItem>
                                    <MenuItem value="Approved"> Approved </MenuItem>
                                    <MenuItem value="Rejected">Rejected </MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl sx={{ mt: 1, width: "-webkit-fill-available" }}>
                                <TextField
                                    fullWidth
                                    type='text'
                                    // value={cardItem.child_card_title}
                                    placeholder="reason.."
                                    label="Reason"
                                    variant="outlined"
                                    name="reason"
                                    multiline
                                    onChange={handleChange}
                                />
                            </FormControl>
                            <LoadingButton
                                fullWidth
                                type="submit"
                                variant="contained"
                                size="large"
                                sx={{ mb: 3 }}
                            // loading={isSubmitting || saveMutation.isLoading}
                            >Save</LoadingButton>
                        </Div>
                    </Form>
                );
            }}
        </Formik>
    );
};
ExpenseApprovalFrom.defaultProps = {
    onSave: () => {
    }
};
export default ExpenseApprovalFrom;
