import * as yup from "yup";
import React, { useEffect, useState } from 'react';
// import {useMutation} from "react-query";
// import {contactService} from "../../../../../services/contact-services";
import JumboDemoCard from "@jumbo/components/JumboDemoCard/JumboDemoCard";
import Div from "@jumbo/shared/Div";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import LoadingButton from "@mui/lab/LoadingButton";
import { FormControl, TextField, Typography, InputLabel, OutlinedInput, Select, MenuItem } from '@mui/material';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import DndWrapper from "./DndWrapper";
import { useDropzone } from "react-dropzone";
import subTaskService from "app/services/subtask_service";
import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import { hraxios } from "app/services/config";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";

import { getVendorName, VendorNameCleanUp } from 'app/redux/actions/subTaskAction';
import { useDispatch, useSelector } from 'react-redux';
import moment from "moment";

const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16,
};

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box',
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden',
};

const img = {
    display: 'block',
    width: 'auto',
    height: '100%',
};
const OperationalExpenseForm = ({ expanseId }) => {
    const Swal = useSwalWrapper();
    const [files, setFiles] = useState([]);
    const [expenseDoc, setExpenseDoc] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isDropzoneEmpty, setIsDropzoneEmpty] = React.useState(true);
    const [expenseData, setExpenseData] = useState({});
    const { hideDialog } = useJumboDialog();
    const { setAuthToken } = useJumboAuth();
    const [age, setAge] = useState(" ");
    const { vendorNameData } = useSelector(state => state.typereducer);
    const [selectedVendorId, setSelectedVendorId] = useState('');
    const dispatch = useDispatch();
    const [queryOptions, setQueryOptions] = useState({});
    const [selectedValue, setSelectedValue] = useState('');
    const [offset, setOffset] = useState(0);
    const limit = 30;
    const [totalCount, setTotalCount] = useState(0);
    const [rows, setRows] = useState([]);
    useEffect(() => {
        const rowData = vendorNameData.map((item) => {
            return (
                {

                    vendorId: item?.id,
                    vendorName: item?.vendor_name,

                }
            )
        })
        setRows(rowData)
    }, [])


    // const rows = vendorNameData.map((item) => {
    //     //console.log('item',item)
    //     return (
    //       {

    //         vendorId: item?.id,
    //         vendorName: item?.vendor_name,

    //       }
    //     )
    //   })
    // //console.log('Mapped Rows:', rows);

    const handleVendorChange = (event) => {
        setSelectedVendorId(event.target.value); // Update the selected vendor ID state
    };

    var filterString;
    useEffect(() => {
        if (queryOptions?.filterModel?.items?.[0]?.columnField != undefined) {

            filterString = `&${queryOptions?.filterModel?.items?.[0]?.columnField}=${(queryOptions?.filterModel?.items?.[0]?.value) ? queryOptions?.filterModel?.items?.[0]?.value : ''}`

        } else {
            filterString = ''
        }
        subTaskService.getVendorName(offset, limit, filterString).then((data) => {
            setTotalCount(data?.data?.count);
            dispatch(getVendorName(data?.data?.data))

        }).catch((err) => {
            if (err?.response?.status == 401) {
                setAuthToken(null);
            }
        })
        return () => {
            dispatch(VendorNameCleanUp())
        }
    }, [offset, queryOptions, rows, expenseData, expanseId])

    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*,application/pdf',
        onDrop: acceptedFiles => {
            onFileUploadInput(acceptedFiles)
            setFiles(
                acceptedFiles.map(file =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    }),
                ),
            );
        },
    });
    useEffect(() => {
        if (expanseId !== undefined) {
            subTaskService.getOpExpenseDataById(expanseId).then((data) => {
                setExpenseData(data?.data?.data)
                setExpenseDoc(data?.data?.data?.bill_reciept_documents)
            }).catch((err) => {
                if (err?.response?.status == 401) {
                    setAuthToken(null);
                }
            })
        }
    }, [expanseId])
    useEffect(() => {
        // Update the selectedValue when vendorData changes
        if (expenseData && expenseData.gst_type) {
            setSelectedValue(expenseData.gst_type);
        }
    }, [expenseData]);
    const thumbs = files.map(file => (
        <div style={thumb} key={file.name}>
            <div style={thumbInner}>
                <img src={file.preview} style={img} alt="" />
            </div>
        </div>
    ));
    const onFileUploadInput = async (file) => {
        // const file = document.getElementById("fileuploadinput-").files;
        const fileLength = file.length;
        let allDoc = []
        setIsLoading(true)
        if (fileLength > 0) {
            for (let i = 0; i < fileLength; i++) {
                let form_Data = new FormData();
                form_Data.append("attachment", file[i]);
                const response = await hraxios.post(`operational_expenses/op-expenses-upload`, form_Data);
                // const response = await ajax(CONSTANTS.API_METHODS.POST, CONSTANTS.API.SUB_TASK_VIDEO_UPLOAD, {}, {}, form_Data);
                if (response.status === 200) {
                    // //console.log("response---", response);
                    let newDoc = {
                        key: response?.data?.data?.id?.key,
                        base_url: response?.data?.data?.id?.base_url,
                    }
                    allDoc.push(newDoc);
                    setExpenseDoc(allDoc)
                } else {
                    sweetAlerts('error')
                }
            }
            setIsLoading(false)
        } else {
            setIsLoading(false)
            sweetAlerts('error')
        }
    };
    const sweetAlerts = variant => {
        Swal.fire({
            icon: variant,
            title: variant,
            text: variant === "success" ? 'Expense Entered Successfully!' : "Something went wrong",
        }).then(result => {
            window.location.reload();
        });
    };
    const handleFileUpload = (files) => {
        // Update the `expenseDoc` state with the uploaded files
        setExpenseDoc(files);

        // Check if files are present and update the state accordingly
        setIsDropzoneEmpty(files.length === 0);
    };

    return (
        <Formik
            enableReinitialize
            initialValues={{
                vendorId: expenseData?.vendor_id ? expenseData?.vendor_id?._id : null,
                expenseAmount: expenseData?.expense_amount ? expenseData?.expense_amount : null,
                expenseDate: expenseData?.expense_date ? moment(expenseData?.expense_date).format('YYYY-MM-DD') : '',
                expenseDescription: expenseData?.expense_description ? expenseData?.expense_description : '',
                expenseNote: expenseData?.expense_note ? expenseData?.expense_note : '',
                gstType: expenseData?.gst_type ? expenseData?.gst_type : '',
                invoiceNumber: expenseData?.invoice_number ? expenseData?.invoice_number : '',
            }}
            validationSchema={yup.object().shape({
                expenseAmount: yup.number()
                    .typeError('Expense amount must be a number')
                    .required('Expense amount is required')
                    .test(
                        'is-decimal',
                        'Invalid format. Please enter a valid number ',
                        value => (value === undefined || value === null ? true : /^(\d+)?(\.\d{1,2})?$/.test(value.toString()))
                    ),
                vendorId: yup.string().nullable().required('Vendor is required'),
                gstType: yup.string().required('GST Type is required'),
                files: yup.array().min(1, 'At least one file is required'),
                expenseDate: yup.string().required('Expense Date is required'),
                expenseDescription: yup.string().required('Description is required'),
                invoiceNumber: yup.string().required('Invoice No. is required')
            })}
            onSubmit={(values) => {
                //console.log("onSubmit:", values);
                if (expenseDoc?.length == 0 && vendorNameData?.find(v=>v.id == values?.vendorId)?.vendor_type != "rent") { //if (expenseDoc?.length == 0) { //values?.category?.length >= 1 && values?.category?.[0] != "rentals" && 
                    return false;
                  }
                let payload = {
                    vendor_id: values?.vendorId,
                    expense_description: values?.expenseDescription,
                    expense_amount: values?.expenseAmount,
                    expense_date: values?.expenseDate,
                    bill_reciept_documents: expenseDoc,
                    status: "Requested",
                    expense_note: values?.expenseNote,
                    invoice_number: values?.invoiceNumber,
                    gst_type: values?.gstType

                    // bill_reciept_documents: {
                    //     key: files,
                    //     base_url: files
                    // },
                }
                if (expanseId !== undefined) {
                    subTaskService.updateOpExpenseStatus(payload, expanseId).then((data) => {
                        hideDialog()
                        sweetAlerts('success')
                    }).catch((err) => {
                        hideDialog()
                        if (err?.response?.status == 401) {
                            sweetAlerts('error')
                        }
                    })
                } else {
                    subTaskService.addOpExpensePostApi(payload).then((data) => {
                        //console.log('op')
                        hideDialog()
                        sweetAlerts('success')
                    }).catch((err) => {
                        hideDialog()
                        if (err?.response?.status == 401) {
                            sweetAlerts('error')
                        }
                    })
                    sweetAlerts('success')
                }
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                // isSubmitting,
                touched,
                values,
                dirty
            }) => {
                //console.log('values==>', values)
                const handleGSTChange = (event) => {
                    const newValue = event.target.value;
                    setSelectedValue(newValue);


                };

                return (
                    <Form noValidate autoComplete="off">
                        <Div
                            sx={{
                                '& .MuiTextField-root': {
                                    mb: 3
                                },
                            }}
                        >
                            <FormControl sx={{ my: 2, width: "-webkit-fill-available" }}>
                                <TextField
                                    fullWidth
                                    type='number'
                                    value={values?.expenseAmount}
                                    placeholder="Expense amount.."
                                    label="Expense Amount"
                                    variant="outlined"
                                    name="expenseAmount"
                                    onChange={handleChange}
                                    style={{ marginBottom: '2px' }}

                                />
                                <ErrorMessage name="expenseAmount" component="div" style={{ color: 'red' }} />

                            </FormControl>
                            <FormControl sx={{ mt: 1, mb: 3 }} fullWidth>
                                <InputLabel id="demo-simple-select-label" >Vendor</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Vendor"
                                    name='vendorId'
                                    value={values?.vendorId}
                                    onChange={(e) => {
                                        setFieldValue('vendorId', e?.target?.value);
                                    }}
                                >
                                    {vendorNameData.map((item) => (
                                        <MenuItem key={item.id} value={item.id}>
                                            {item.vendor_name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <ErrorMessage name="vendorId" component="div" style={{ color: 'red' }} />
                            </FormControl>
                            <FormControl sx={{ mt: 1, mb: 3 }} fullWidth>
                                <InputLabel id="demo-simple-select-label" >GST Tax Type</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="GST Tax Type"
                                    name='gstType'
                                    value={values?.gstType}
                                    onChange={(e) => {
                                        setFieldValue('gstType', e?.target?.value);
                                    }}
                                >
                                    <MenuItem value={'intra_state'}>Intra-state</MenuItem>
                                    <MenuItem value={'inter_state'}>Inter-state</MenuItem>


                                </Select>

                                <ErrorMessage name="gstType" component="div" style={{ color: 'red' }} />
                            </FormControl>
                            <FormControl sx={{ my: 1, width: "-webkit-fill-available" }}>
                            <TextField
                                fullWidth
                                type='text'
                                value={values?.invoiceNumber}
                                // value={cardItem.child_card_title}
                                placeholder="enter invoice number"
                                label="Invoice Number"
                                variant="outlined"
                                name="invoiceNumber"
                                multiline
                                onChange={handleChange}
                                style={{ marginBottom: '2px' }}
                            />
                            <ErrorMessage name="invoiceNumber" component="div" style={{ color: 'red' }} />
                            </FormControl>
                            <FormControl  sx={{ my: 2, width: "-webkit-fill-available" }}>
                                <TextField
                                    id="expenseDate"
                                    label="Expense Date"
                                    type="date"
                                    // sx={{ width: 220 }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={handleChange}
                                    value={values?.expenseDate}
                                    style={{ marginBottom: '2px' }}
                                />
                                <ErrorMessage name="expenseDate" component="div" style={{ color: 'red' }} />
                            </FormControl>
                            <FormControl  sx={{ my: 2, width: "-webkit-fill-available" }}>
                                <TextField
                                    fullWidth
                                    type='text'
                                    value={values?.expenseDescription}
                                    placeholder="Description.."
                                    label="Description"
                                    variant="outlined"
                                    name="expenseDescription"
                                    multiline
                                    onChange={handleChange}
                                    style={{ marginBottom: '2px' }}
                                />
                                <ErrorMessage name="expenseDescription" component="div" style={{ color: 'red' }} />
                            </FormControl>
                            <FormControl sx={{ mt: 1, width: "-webkit-fill-available" }}>
                                <TextField
                                    fullWidth
                                    type='text'
                                    value={values?.expenseNote}
                                    placeholder="Note.."
                                    label="Note"
                                    variant="outlined"
                                    name="expenseNote"
                                    multiline
                                    onChange={handleChange}
                                />
                            </FormControl>
                            <FormControl>
                            <JumboDemoCard

                            >
                                <Div>
                                    <DndWrapper>
                                        <div {...getRootProps({ className: 'dropzone' })}>
                                            <input {...getInputProps()} required/>
                                            <Typography variant={"body1"}>Drag 'n' drop some files here, or click to select
                                                files</Typography>
                                        </div>
                                    </DndWrapper>
                                    <aside style={thumbsContainer}>{thumbs}</aside>
                                    {expenseDoc.map((doc, index) =>
                                        <a href={"https://d20vmeuwl3jwbk.cloudfront.net/" + doc?.base_url + "/" + doc?.key} target="_blank"><Typography variant={"h6"} mb={.5}>{index + 1}. {doc?.key}</Typography></a>

                                        // <aside style={thumbsContainer}>{doc}</aside>
                                    )}
                                </Div>

                            </JumboDemoCard>
                            {
                                    (dirty && expenseDoc?.length == 0 && vendorNameData?.find(v=>v.id == values?.vendorId)?.vendor_type != "rent")
                                    &&
                                    <div style={{color:'red', marginTop:'2px'}}>Attachment is required</div>
                                }
                            </FormControl>
                            {
                                isLoading ?
                                    <LoadingButton
                                        fullWidth
                                        type="submit"
                                        variant="contained"
                                        size="large"
                                        disabled={true}
                                        sx={{ mb: 3 }}
                                    // loading={isSubmitting || saveMutation.isLoading}
                                    >Save</LoadingButton> :
                                    <LoadingButton
                                        fullWidth
                                        type="submit"
                                        variant="contained"
                                        size="large"
                                        sx={{ mb: 3 }}
                                    // loading={isSubmitting || saveMutation.isLoading}
                                    >Save</LoadingButton>
                            }
                        </Div>
                    </Form>

                );
            }}
        </Formik>
    );
};
OperationalExpenseForm.defaultProps = {
    onSave: () => {
    }
};
export default OperationalExpenseForm;
