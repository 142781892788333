import React, { useEffect, useState } from 'react';
import Grid from "@mui/material/Grid";
import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import { alpha } from "@mui/material/styles";
import DetailedInfo from './DetailedInfo';
import ChipsAddRemove from './chips';
import subTaskService from 'app/services/subtask_service';
import { useDispatch, useSelector } from 'react-redux';
import { subtaskCameraDetails, subtaskDetailGetData, subtaskDetailsCleanUp, subtaskDetailsGetMySubstask, subtaskStudioDetails } from 'app/redux/actions/subTaskAction';
import Header from './header';
import { Button, Card, CardHeader, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import { subtaskDetailGetDataSideInfo } from 'app/redux/actions/subTaskAction';
import useJumboAuth from '@jumbo/hooks/useJumboAuth';
import BoxsAddRemove from './BoxCard';
import AdvanceFeatureBox from './AdvanceFeature';




const DetailedSubTasks = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { setAuthToken } = useJumboAuth();

    const { theme } = useJumboTheme();
    const { detailedInfos, detailedGetMySubTask, detailedSideInfos } = useSelector(state => state.typereducer)
    const Swal = useSwalWrapper();

    const [boxWiseTakeDetails, setBoxWiseTakeDetails] = useState([]);
    const [boxWiseDetails, setBoxWiseDetails] = useState([]);
    const [advancefeatureDetails, setAdvancefeatureDetails] = useState([]);
    const urlN = window.location.href;
    let url = new URL(urlN);
    let searchParam = url.searchParams;
    let subTaskId = searchParam.get('subTaskId');
    const updatedBoxWiseTakeDetails = Object.assign([], boxWiseTakeDetails);
    const updatedBoxWiseDetails = Object.assign([], boxWiseDetails);
    const updatedAdvanceFeatureDetails = Object.assign([], advancefeatureDetails)

    const sweetAlerts = variant => {
        Swal.fire({
            icon: variant,
            title: variant,
            text: 'Data Entered Successfully!',
            target: navigate('/footages')
        });
    };

    const onSubmitTakeDetails = () => {

        const payload = {
            "task_id": detailedInfos?.task_id,
            "project_id": detailedInfos?.project_id,
            "subtask_id": detailedInfos?._id,
            "location": detailedSideInfos?.dmfTask?.[0].location?.[0],
            "box_info": boxWiseTakeDetails,
            "subTask_box_info": updatedBoxWiseDetails,
            "advance_feature": advancefeatureDetails
            // "check_box":checkBox
        }
        //console.log("payload===>", payload);
        subTaskService.subtaskDetailsInfoPostApi(payload).then((data) => {

        }).catch((err) => {
            if (err?.response?.status == 401) {
                setAuthToken(null);
            }
        })
        sweetAlerts('success')

    }


    /*
        {
            "task_id": "6365758c3503bfabc52b7cf7",
            "project_id":"6300c7c8ca95d71cb087f41a",
            "subtask_id": "6365758c3503bfabc52b7d10",
            "box_info": [
                {
                    "box_id": "638a9408ce31bbf86724c391",
                    "box_title": "Intro",
                    check_box_flag,
                    "take_names": [
                        "CAM1-1103",
                        "CAM1-1104"
                    ]
                },
                {
                    "box_id": "638a9408ce31bbf86724c391",
                    "box_title": "Intro",
                    check_box_flag,
                    "take_names": [
                        "CAM1-1105",
                        "CAM1-1106"
                    ]
                }
            ]
        }
    */


    const updateBoxIdTakeDetails = (boxId, box_title, take_details, check_box_flag) => {
        const foundIndex = updatedBoxWiseTakeDetails.findIndex(itm => itm.box_id == boxId);
        if (foundIndex == -1) {
            updatedBoxWiseTakeDetails.push({
                box_id: boxId,
                box_title,
                check_box_flag,
                take_details: take_details?.map(i => ({
                    take_name: i?.label,
                    studio: i?.studio,
                    date: i?.date,
                    camera_name: i?.camera_name

                }))
            })
        } else {
            updatedBoxWiseTakeDetails[foundIndex] = {
                box_id: boxId,
                box_title,
                check_box_flag,
                take_details: take_details?.map(i => ({
                    take_name: i?.label,
                    studio: i?.studio,
                    date: i?.date,
                    camera_name: i?.camera_name
                }))

            };
        }
        setBoxWiseTakeDetails(updatedBoxWiseTakeDetails);
    }
    const updateBoxIdValue = (boxId, value) => {
        const foundIndex = updatedBoxWiseDetails.findIndex(itm => itm._id == boxId);
        updatedBoxWiseDetails[foundIndex]['field_content'] = value;
        setBoxWiseDetails(updatedBoxWiseDetails);
    }
    const updateAdvanceFeatureBoxIdValue = (boxId, title, isMultiple, value) => {
        const foundIndex = updatedAdvanceFeatureDetails.findIndex(itm => itm._id == boxId);
        if (isMultiple)
            updatedAdvanceFeatureDetails[foundIndex]['box_value'] = value;
        else
            updatedAdvanceFeatureDetails[foundIndex]['box_value'] = [value];
        setAdvancefeatureDetails(updatedAdvanceFeatureDetails);
    }

    // //console.log("updatedBoxWiseDetails--", updatedBoxWiseDetails);
    useEffect(() => {


        subTaskService.subtaskdetails(subTaskId).then((data) => {
            // //console.log("detail data-Role---", data?.data?.[0]?.offline_boxes?.filter(itm => itm?.input_type == "Role"));
            setAdvancefeatureDetails(data?.data?.[0]?.offline_boxes?.filter((item) => (item?.out_field_key === "influencer" || item?.out_field_key === "dop")))
            setBoxWiseDetails(data?.data?.[0]?.sub_task_data?.filter(itm => itm?.field_type !== "Document"))
            dispatch(subtaskDetailGetData(data?.data?.[0]))

            subTaskService.subtaskdetailssideinfo(data?.data?.[0]?._id, data?.data?.[0]?.task_id).then((item) => {
                dispatch(subtaskDetailGetDataSideInfo(item?.data?.[0]))
            }).catch((err) => {
                if (err?.response?.status == 401) {
                    setAuthToken(null);
                }
            })
        }).catch((err) => {
            if (err?.response?.status == 401) {
                setAuthToken(null);
            }
        })

        subTaskService.getmysubtaskdetails(subTaskId).then((data) => {
            dispatch(subtaskDetailsGetMySubstask(data))
        }).catch((err) => {
            if (err?.response?.status == 401) {
                setAuthToken(null);
            }
        })

        var typecamera = "camera"
        subTaskService.subTaskGetStudioAndCameraList(typecamera).then((data) => {
            dispatch(subtaskCameraDetails(data?.data?.data?.data?.custom_list))
        }).catch((err) => {
            if (err?.response?.status == 401) {
                setAuthToken(null);
            }
        })

        var typestudio = "studio"
        subTaskService.subTaskGetStudioAndCameraList(typestudio).then((data) => {
            dispatch(subtaskStudioDetails(data?.data?.data?.data?.custom_list))
        }).catch((err) => {
            if (err?.response?.status == 401) {
                setAuthToken(null);
            }
        })

        return () => {
            dispatch(subtaskDetailsCleanUp())
        }

    }, [])
    return (
        <JumboContentLayout
            header={<Header />}
            sidebar={<Grid container spacing={3.75}>
                {
                    detailedInfos?.sub_task_data?.length > 0 &&
                    <Typography sx={{ margin: '5rem 0 0 2rem' }} variant={"h4"} mb={0}>Documents</Typography>
                }
                {
                    detailedInfos?.sub_task_data?.filter(itm => itm?.field_type == "Document")?.map(box => {
                        return (
                            <Grid item xs={12} md={6} lg={12}>

                                <ChipsAddRemove
                                    setBoxTakeDetails={updateBoxIdTakeDetails}
                                    boxId={box._id}
                                    //changes
                                    // detailed={detailedGetMySubTask}
                                    title={box.field_name}
                                    subheader={box._id}

                                />

                            </Grid>
                        )
                    })
                }
                {
                    updatedBoxWiseDetails?.length > 0 &&
                    <Typography sx={{ margin: '5rem 0 0 2rem' }} variant={"h4"} mb={0}>Other Inputs</Typography>
                }
                {
                    updatedBoxWiseDetails?.map(box => {
                        return (
                            <Grid item xs={12} md={6} lg={12}>
                                <BoxsAddRemove
                                    setBoxTakeDetails={updateBoxIdValue}
                                    boxId={box._id}
                                    //changes
                                    // detailed={detailedGetMySubTask}
                                    value={box.field_content}
                                    title={box.field_name}
                                    subheader={box._id}

                                />
                            </Grid>
                        )
                    })
                }
                {
                    updatedAdvanceFeatureDetails?.length > 0 &&
                    <Typography sx={{ margin: '5rem 0 0 2rem' }} variant={"h4"} mb={0}>Advance Feature</Typography>

                }
                {
                    updatedAdvanceFeatureDetails?.map(offline_box => {
                        return (
                            <Grid item xs={12} md={6} lg={12}>
                                <AdvanceFeatureBox
                                    setBoxTakeDetails={updateAdvanceFeatureBoxIdValue}
                                    boxId={offline_box._id}
                                    boxInfo={offline_box}
                                    //changes
                                    // detailed={detailedGetMySubTask}
                                    value={offline_box.box_value === "" ? [] : offline_box.box_value}
                                    title={offline_box.box_name}
                                    subheader={offline_box._id}

                                />

                            </Grid>
                        )
                    })
                }
                <Grid item xs={12} md={6} lg={12} style={{ paddingBlock: 40 }}>
                    <Button className="" variant="contained" onClick={() => {
                        onSubmitTakeDetails();
                    }}>Submit</Button>

                </Grid>

            </Grid>}
            layoutOptions={{
                header: {
                    sx: {
                        mt: -4,
                        mb: -7.25,
                        mx: { xs: -4, lg: -6 },
                        p: { xs: theme.spacing(6, 4, 11), lg: theme.spacing(6, 6, 11) },
                        backgroundSize: 'cover',
                        color: 'common.white',
                        position: 'relative',

                        '&::after': {
                            display: 'inline-block',
                            position: 'absolute',
                            content: `''`,
                            inset: 0,
                            backgroundColor: alpha(theme.palette.common.black, .65)
                        }
                    }
                },
                sidebar: {
                    sx: {
                        mr: 3.75,
                        mt: 3.75,
                        width: { xs: '100%', lg: '66%' },
                        [theme.breakpoints.down('lg')]: {
                            minHeight: 0,
                            mr: 0,
                            order: 2
                        }
                    }
                },
                wrapper: {
                    sx: {
                        [theme.breakpoints.down('lg')]: {
                            flexDirection: 'column'
                        }
                    }
                },
                main: {
                    sx: {
                        [theme.breakpoints.down('lg')]: {
                            minHeight: 0
                        },
                    }
                }
            }}
        >
            <DetailedInfo />
        </JumboContentLayout>
    );
};

export default DetailedSubTasks;
