import React, { useState } from 'react';
// import UserItem from "./UserItem";
import { Button, CircularProgress, Typography } from "@mui/material";
import { useEffect } from 'react';
// import { Search, SearchIconWrapper, StyledInputBase } from "./style";
import Div from '@jumbo/shared/Div';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import { allAutomatedVoData } from 'app/redux/actions/subTaskAction';
import subTaskService from 'app/services/subtask_service';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import AutomatedVotem from './AutomatedVoItem';

const AutomatedVoDetail = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [counter, setCounter] = useState(0);
    const [totalLength, setTotalLength] = useState(0);
    const { state } = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { automatedVO } = useSelector(state => state.typereducer);
    const Swal = useSwalWrapper();
    const sweetAlerts = (message) => {
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: message,
        }).then(result => {
            navigate('/automated-scripts')
        });
    };
    const getVoAudioFile = async (votText, fileName) => {
        let newVoData = {}
        await subTaskService.getAutomatedVoByAsin(votText, fileName).then((data) => {
            newVoData = data;
        }).catch((err) => {
            if (err?.response?.status == 401) {
            }
        })
        return newVoData;
    }
    useEffect(() => {
        setIsLoading(true)
        async function getVoData() {
            if (state?.length > 0) {
                let allVoByAsin = []
                setTotalLength(state.length)
                for (let i = 0; i < state.length; i++) {
                    // setIsLoading(true)
                    let voDetail = []
                    for (let j = 1; j < 16; j++) {
                        const key = "v" + j;
                        const time = "t" + j;
                        if (state?.[i]?.[key] !== '') {
                            const result = await getVoAudioFile(state?.[i]?.[key], `${state[i]?.asinNumber}_VO${j}`)
                            voDetail.push(result);
                        }
                        else {
                            voDetail.push({
                                duration: 0,
                                file_path: '-'
                            });
                        }
                    }
                    const automatedVoByAsin = {};

                    voDetail.forEach((item, index) => {
                        automatedVoByAsin['v' + (index + 1)] = item['file_path'];
                        automatedVoByAsin['t' + (index + 1)] = item['duration'];
                    });
                    automatedVoByAsin.asinNumber = state[i]?.asinNumber
                    allVoByAsin.push(automatedVoByAsin);
                    // //console.log("allVoByAsin--", allVoByAsin);
                    dispatch(allAutomatedVoData(allVoByAsin))
                    setCounter(i + 1)
                }
                setIsLoading(false)
            } else { setIsLoading(false); sweetAlerts('Enter some Asin & Vo!') }
        }
        getVoData()
    }, [])
    // //console.log("automatedVO---", automatedVO);
    const handleExport = () => {
        // //console.log(" handleExport automatedVO----", automatedVO);

        const mockData = automatedVO.map((item, index) => {
            let staticColumn = ({
                "id": index + 1,
                "asinNumber": item?.asinNumber ? item?.asinNumber : "-",
                "v1": item?.v1 !== '-' ? "https://d20vmeuwl3jwbk.cloudfront.net/" + item?.v1 : '--',
                "t1": item?.t1,
                "v2": item?.v2 !== '-' ? "https://d20vmeuwl3jwbk.cloudfront.net/" + item?.v2 : '--',
                "t2": item?.t2,
                "v3": item?.v3 !== '-' ? "https://d20vmeuwl3jwbk.cloudfront.net/" + item?.v3 : '--',
                "t3": item?.t3,
                "v4": item?.v4 !== '-' ? "https://d20vmeuwl3jwbk.cloudfront.net/" + item?.v4 : '--',
                "t4": item?.t4,
                "v5": item?.v5 !== '-' ? "https://d20vmeuwl3jwbk.cloudfront.net/" + item?.v5 : '--',
                "t5": item?.t5,
                "v6": item?.v6 !== '-' ? "https://d20vmeuwl3jwbk.cloudfront.net/" + item?.v6 : '--',
                "t6": item?.t6,
                "v7": item?.v7 !== '-' ? "https://d20vmeuwl3jwbk.cloudfront.net/" + item?.v7 : '--',
                "t7": item?.t7,
                "v8": item?.v8 !== '-' ? "https://d20vmeuwl3jwbk.cloudfront.net/" + item?.v8 : '--',
                "t8": item?.t8,
                "v9": item?.v9 !== '-' ? "https://d20vmeuwl3jwbk.cloudfront.net/" + item?.v9 : '--',
                "t9": item?.t9,
                "v10": item?.v10 !== '-' ? "https://d20vmeuwl3jwbk.cloudfront.net/" + item?.v10 : '--',
                "t10": item?.t10,
                "v11": item?.v11 !== '-' ? "https://d20vmeuwl3jwbk.cloudfront.net/" + item?.v11 : '--',
                "t11": item?.t11,
                "v12": item?.v12 !== '-' ? "https://d20vmeuwl3jwbk.cloudfront.net/" + item?.v12 : '--',
                "t12": item?.t12,
                "v13": item?.v13 !== '-' ? "https://d20vmeuwl3jwbk.cloudfront.net/" + item?.v13 : '--',
                "t13": item?.t13,
                "v14": item?.v14 !== '-' ? "https://d20vmeuwl3jwbk.cloudfront.net/" + item?.v14 : '--',
                "t14": item?.t14,
                "v15": item?.v15 !== '-' ? "https://d20vmeuwl3jwbk.cloudfront.net/" + item?.v15 : '--',
                "t15": item?.t15,
                // "automatedVo": item?.file_path ? "https://d20vmeuwl3jwbk.cloudfront.net/" + item?.file_path : '--',
            })
            return staticColumn;
        })
        function convertToCSV(objArray) {
            var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
            var str = '';
            for (var i = 0; i < array.length; i++) {
                var line = '';
                for (var index in array[i]) {
                    if (line != '') line += '++'

                    line += array[i][index];
                }
                const convertedSentence = line.split("++").map(word => `"${word}"`).join(",");
                str += convertedSentence + '\r\n';
            }
            return str;
        }

        function exportCSVFile(headers, items, fileTitle) {
            if (headers) {
                items.unshift(headers);
            }
            // Convert Object to JSON
            var jsonObject = JSON.stringify(items);
            var csv = convertToCSV(jsonObject);

            var exportedFilenmae = fileTitle + '.csv' || 'export.csv';

            var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
            if (navigator.msSaveBlob) { // IE 10+
                navigator.msSaveBlob(blob, exportedFilenmae);
            } else {
                var link = document.createElement("a");
                if (link.download !== undefined) { // feature detection
                    // Browsers that support HTML5 download attribute
                    var url = URL.createObjectURL(blob);
                    link.setAttribute("href", url);
                    link.setAttribute("download", exportedFilenmae);
                    link.style.visibility = 'hidden';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            }
        }

        var headers = {
            id: "ID",
            asinNumber: "Asin Number",
            v1: "VO 1",
            t1: "Time 1",
            v2: "VO 2",
            t2: "Time 2",
            v3: "VO 3",
            t3: "Time 3",
            v4: "VO 4",
            t4: "Time 4",
            v5: "VO 5",
            t5: "Time 5",
            v6: "VO 6",
            t6: "Time 6",
            v7: "VO 7",
            t7: "Time 7",
            v8: "VO 8",
            t8: "Time 8",
            v9: "VO 9",
            t9: "Time 8",
            v10: "VO 10",
            t10: "Time 10",
            v11: "VO 11",
            t11: "Time 11",
            v12: "VO 12",
            t12: "Time 12",
            v13: "VO 13",
            t13: "Time 13",
            v14: "VO 14",
            t14: "Time 14",
            v15: "VO 15",
            t15: "Time 15",
            // automatedVo: "Automated VO",
        };
        var fileTitle = 'Automated Vo Bulk Export'; // or 'my-unique-title'
        exportCSVFile(headers, mockData, fileTitle); // call the exportCSVFile() function to process the JSON and trigger the download
    }

    return (
        <React.Fragment>
            <Typography variant={'h2'} mb={3}>Automated VO</Typography>
            {/* <ButtonGroup
                variant="outlined"
                disableElevation
                sx={{
                    '& .MuiButton-root': {
                        px: 1,
                    }
                }}
            >
                <Button variant={view === "grid" ? "contained" : "outlined"}
                    onClick={() => setView("grid")}><ViewComfyIcon /></Button>
                <Button variant={view === "list" ? "contained" : "outlined"}
                    onClick={() => setView("table")}><ListIcon /></Button>
            </ButtonGroup> */}
            <div style={{ height: '70px', width: '100%' }}>
                {isLoading ?
                    (<Button sx={{ float: 'right' }} disabled variant="contained" onClick={handleExport}>
                        Export CSV
                    </Button>
                    ) :
                    (<Button sx={{ float: 'right' }} variant="contained" onClick={handleExport}>
                        Export CSV
                    </Button>)
                }
            </div>
            {isLoading ?
                <Div sx={{ flexGrow: 1 }}>
                    <CircularProgress color='primary' sx={{ marginLeft: '50%', marginTop: '20%' }} />
                    <Typography sx={{ marginLeft: '25%' }}>
                        <span>Getting your data...</span>
                        <span>it usually takes around 2-3 minutes to get the results --{counter}/{totalLength}</span>
                    </Typography>
                </Div>
                :
                <>
                    {
                        automatedVO?.map((VoData, index) =>
                            <AutomatedVotem VoData={VoData} key={index} />
                        )
                    }
                </>
            }
        </React.Fragment>
    );
};

export default AutomatedVoDetail;
