import styled from "@emotion/styled";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import JumboContent from "@jumbo/components/JumboContent";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Button, Grid } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useEffect } from "react";
import { useNavigate } from 'react-router-dom';

const Item = styled("div")(({ theme }) => ({
    ...theme.typography.body2,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));
const templateDetails = [
    {
        templateName: "Simple Product Graphic",
        templateKey: "simple_product_graphic",
        templateId: "64758327237d1dbd483e2699",
        sampleFile: "/images/sample_simple_product_graphic.csv",
        sampleImg: "https://api.robolly.com/templates/64758327237d1dbd483e2699/render.png?id=1&Product+Image=https%3A%2F%2Frobolly.s3.eu-central-1.amazonaws.com%2Forganizations%2F632de8157558dc8f2060a2d8%2Fuploaded%2FS69ppj9jPuvtNkQejI6gFQZz5IKTLP76T8xLNQMs45iO4tGkTAaFKnBMIcTkzlicQThaJM.png&Product+Name=Fitness+Smartwatch&Background+Image=https%3A%2F%2Frobolly.s3.eu-central-1.amazonaws.com%2Forganizations%2F632de8157558dc8f2060a2d8%2Fuploaded%2F1uMsxLmk9MhJVqew9eujxuWB6A63kpQvEGzLIwLk8eIuPlodO9zvR9C452Jlk6lRqjDsu3.png&API+Key+=YHCRIXGOItVt0UKNLlyHcaiZdJZIleOquT8KH8Fx&sig=2b9b16d280e08cd807fa1e7bb5513e952cad0e9608d8bc79e3908f9643324470",
        headers: ["Product Image", "Product Name", "Background Image", "API Key"]
    },
    {
        templateName: "VSE Product Graphic",
        templateKey: "vse_product_graphic",
        templateId: "64393b7f2fdbceea2acbfc20",
        sampleFile: "/images/sample_vse_product_graphic.csv",
        sampleImg: "images/vse_product_graphic.png",
        headers: ["Product Image", "Product Image Without BG", "Product Name", "Product Feature", "Background Colours", "ASIN", "API Key"]
    },
    {
        templateName: "Live Product Graphic",
        templateKey: "live_product_graphic",
        templateId: "6480773c7987e9f6b969b34c",
        sampleFile: "/images/sample_live_product_graphic.csv",
        sampleImg: "https://api.robolly.com/templates/6480773c7987e9f6b969b34c/render.png?id=1&Live+Logo=https%3A%2F%2Frobolly.s3.eu-central-1.amazonaws.com%2Forganizations%2F632de8157558dc8f2060a2d8%2Fuploaded%2FZK13qIftUa0l9dETlNTfOuMpa2WX0ceVqKYKqDXfK61D1NcjizcOmmbMuldlYzwjUTtjTg.png&Product+Image=https%3A%2F%2Frobolly.s3.eu-central-1.amazonaws.com%2Forganizations%2F632de8157558dc8f2060a2d8%2Fuploaded%2FS69ppj9jPuvtNkQejI6gFQZz5IKTLP76T8xLNQMs45iO4tGkTAaFKnBMIcTkzlicQThaJM.png&Product+Image+Without+BG=https%3A%2F%2Frobolly.s3.eu-central-1.amazonaws.com%2Forganizations%2F632de8157558dc8f2060a2d8%2Fuploaded%2FS69ppj9jPuvtNkQejI6gFQZz5IKTLP76T8xLNQMs45iO4tGkTAaFKnBMIcTkzlicQThaJM.png&Price+Slash=%23ff0000&MRP=1600&Off=OFF&%25+Off=20.00%25&List+Price=1280&Price+Box=%23f6e410&Product+Name2=Fitness+Smartwatch&Product+Name+1=Samsung&%25+Off+BG+Circle=https%3A%2F%2Frobolly.s3.eu-central-1.amazonaws.com%2Forganizations%2F632de8157558dc8f2060a2d8%2Fuploaded%2FVuSngz1lIKmUGc8Ri3QdSdjkdzRjUZLeCECd51hSXptaTdjyZivoSVxOqffS7rRkE4aG2S.png&Yellow+Rectangle+Pdt+Name+1=https%3A%2F%2Frobolly.s3.eu-central-1.amazonaws.com%2Forganizations%2F632de8157558dc8f2060a2d8%2Fuploaded%2FbwilCGVL61qCX6VKhkis4ssBMyjHLHkAeY0Q6yLRXEzqGMGVMhSBFUeMxjRDvga36fiSJ1.png&Black+Rectangle+Pdt+Name+2=https%3A%2F%2Frobolly.s3.eu-central-1.amazonaws.com%2Forganizations%2F632de8157558dc8f2060a2d8%2Fuploaded%2FIwvw0cYRA4snbUJRsmAmdMXM6S18k6yaZ5uARBBiGofl1hj1eV0k9VQxL0SKkcfiJXpIyd.png&Background+Image=https%3A%2F%2Frobolly.s3.eu-central-1.amazonaws.com%2Forganizations%2F632de8157558dc8f2060a2d8%2Fuploaded%2F7mIZoKpgDZYsxT86mPuIgV7MIQifFcTFMUHR81csx9F7gChc4YijNvNv7MJSFWa9CBzCb7.png&API+Key=YHCRIXGOItVt0UKNLlyHcaiZdJZIleOquT8KH8Fx&sig=2b9b16d280e08cd807fa1e7bb5513e952cad0e9608d8bc79e3908f9643324470",
        headers: ["Live Logo", "Product Image", "Product Image Without BG", "Price Slash", "MRP", "Off", "% Off", "List Price", "Price Box", "Product Name2", "Product Name 1", "% Off BG Circle", "Yellow Rectangle Pdt Name 1", "Black Rectangle Pdt Name 2", "Background Image", "API Key"]
    },
    {
        templateName: "Live Gaming Thumbnail",
        templateKey: "live_gaming_thumbnail",
        templateId: "6447a7248a0e8b1c16597dfd",
        sampleFile: "/images/sample_live_gaming_thumbnail.csv",
        sampleImg: "https://api.robolly.com/templates/6447a7248a0e8b1c16597dfd/render.png?id=1&Live+Title+=&Social+Handle+=&Social+Logo+=https%3A%2F%2Frobolly.s3.eu-central-1.amazonaws.com%2Forganizations%2F632de8157558dc8f2060a2d8%2Fuploaded%2F7QhwLQfDQHdugdaRE6UgxGzhG6dhlC4hAU9wbufSsR44HgHTAAqlkfFLY0MfidTDqLObS3.png&Live+Image=https%3A%2F%2Frobolly.s3.eu-central-1.amazonaws.com%2Forganizations%2F632de8157558dc8f2060a2d8%2Fuploaded%2F2QEYErLaw45JE8ElTk10jYR7wa3XIRK8YsfqMWBCRZHf1duGa6J4utg5GWYLBIgyfm4Egz.png&Date+=7th+Jun+12am&Live+Logo+=https%3A%2F%2Frobolly.s3.eu-central-1.amazonaws.com%2Forganizations%2F632de8157558dc8f2060a2d8%2Fuploaded%2Fw3p60fetnYO4I9IGS06IRGXd6QKETpAVv4fDQbLp6sdmISdRD7ssaAulemVDbES5QGnzn9.png&Influencer+Name+=Sarcastic+Sindhi&Influencer+Name+BG=%23000000&Influencer+Image=&Background+Image=https%3A%2F%2Frobolly.s3.eu-central-1.amazonaws.com%2Forganizations%2F632de8157558dc8f2060a2d8%2Fuploaded%2FnTutNBHkukvgZsMgyi0gDTU4HnYU0QSeFWy9CRg3wBIjwfizR6foafvM08PtSkGQ9YwzYd.png&API+Key+=YHCRIXGOItVt0UKNLlyHcaiZdJZIleOquT8KH8Fx&sig=2b9b16d280e08cd807fa1e7bb5513e952cad0e9608d8bc79e3908f9643324470",
        headers: ["Live Title", "Social Handle", "Social Logo", "Live Image", "Date", "Influencer Name", "Influencer Name BG", "Influencer Image", "Background Image", "API Key"]
    },
]
const AutomatedGraphicsTemplates = () => {
    const navigate = useNavigate();

    const onTemplateClick = (template) => {
        // //console.log("templatetemplate---", template);
        navigate(`/automated-graphics?&template=${template?.templateId}`, { state: template })
    }
    return (
        <Grid container spacing={3.75}>
            {
                templateDetails.map((template, index) => (
                    <Grid item xs={6} sm={6} lg={4}>
                        <JumboCardQuick noWrapper>
                            <JumboContent
                                action={<MoreHorizIcon />}
                                bgImage={template?.sampleImg}
                                // bgImage={getAssetPath(`${ASSET_IMAGES}/event2.jpg`, "420x380")}
                                backdrop
                                sx={{ height: 265 }}
                            >
                                <Stack alignItems={"center"} sx={{ p: theme => theme.spacing(0, 2), mt: -2 }}>
                                    <Typography variant={"h5"} color={"common.white"}>
                                        {template?.templateName}
                                    </Typography>
                                    <Typography variant={"h6"} color={"common.white"}>
                                        {template?.productDescription}
                                    </Typography>
                                </Stack>
                            </JumboContent>
                            <CardContent>
                                <Button variant="contained" onClick={() => onTemplateClick(template)}>
                                    Use Template
                                </Button>
                            </CardContent>
                        </JumboCardQuick>
                    </Grid>
                ))
            }
        </Grid>
        // </>
    );
};

export default AutomatedGraphicsTemplates;
