import React, { useState } from 'react';
// import UserItem from "./UserItem";
import { Button, CircularProgress, Typography } from "@mui/material";
import { useEffect } from 'react';
// import { Search, SearchIconWrapper, StyledInputBase } from "./style";
import Div from '@jumbo/shared/Div';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import ApifyClient from 'apify-client';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import AutomatedScriptItem from './AutomatedScriptItem';
import { scriptaxios } from 'app/services/config';
async function callOpenAiApi(model, prompt, temperature) {
    // //console.log("callOpenAiApi---", model, prompt);
    // Set the OpenAI API endpoint and your API key
    const API_ENDPOINT = 'https://api.openai.com/v1/chat/completions';
    // const API_KEY = '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';
    const API_KEY = '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';

    const url = API_ENDPOINT;
    const data = {
        model: model,
        // model: model,
        prompt: prompt,
    };
    const options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${API_KEY}`,
        },
        // payload: JSON.stringify(data),
    };
    const response = await scriptaxios.post('api/predict', data, options);
    return response;
}
const AutomatedScriptDetailData = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isProcessed, setProcessed] = useState(false);
    const [view, setView] = React.useState("grid");
    const { state } = useLocation();
    const navigate = useNavigate();
    const [totalLength, setTotalLength] = useState(0);
    const [counter, setCounter] = useState(0);
    const [failedCounter, setFailedCounter] = useState(0);

    const [automatedScriptsDetailData, setAutomatedScriptsDetailData] = React.useState([]);
    const Swal = useSwalWrapper();
    const sweetAlerts = (message) => {
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: message,
        }).then(result => {
            navigate('/automated-scripts')
        });
    };
    useEffect(() => {
        setIsLoading(true)
        async function getScriptData() {
            setProcessed(false)

            if (state?.rows?.length > 0) {
                setTotalLength(state?.rows?.length)
                // setIsLoading(true)
                let temperature = 0.3;
                let scriptDetail = [];
                let sucCount = 0;
                let failCount = 0;
                for (let i = 0; i < state.rows.length; i++) {
                    try {

                        const result = await callOpenAiApi(state?.modelId, state?.rows[i]?.prompt, temperature);
                        scriptDetail.push({
                            asin: state?.rows[i]?.id,
                            content: result.data.completion
                        });
                        ++sucCount;
                        setCounter(sucCount);
                        setAutomatedScriptsDetailData(scriptDetail);

                    } catch (e) {
                        //console.log('error processing record', i);
                        ++failCount
                        setFailedCounter(failCount)

                    }
                    // Use the 'fetch' method from the 'UrlFetchApp' library to call the OpenAI API

                }
                setIsLoading(false)
                setProcessed(true)
                // //console.log("callOpenAiApi---scriptDetail", scriptDetail);
            } else { setIsLoading(false); sweetAlerts('Something went wrong!') }
        }
        getScriptData()
    }, [])
    // //console.log("automatedScriptsDetailData---", automatedScriptsDetailData);
    const handleExport = () => {
        // //console.log("automatedScriptsDetailData----", automatedScriptsDetailData);

        const mockData = automatedScriptsDetailData.map((item, index) => {
            let staticColumn = ({
                "id": index + 1,
                "asin": item?.asin ? item?.asin : '-',
                "content": item?.content ? item?.content : '-',
            })
            return staticColumn;
        })
        function convertToCSV(objArray) {
            var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
            var str = '';
            for (var i = 0; i < array.length; i++) {
                var line = '';
                for (var index in array[i]) {
                    if (line != '') line += '++'

                    line += array[i][index];
                }
                const convertedSentence = line.split("++").map(word => `"${word}"`).join(",");
                str += convertedSentence + '\r\n';
            }
            return str;
        }

        function exportCSVFile(headers, items, fileTitle) {
            if (headers) {
                items.unshift(headers);
            }
            // Convert Object to JSON
            var jsonObject = JSON.stringify(items);
            var csv = convertToCSV(jsonObject);

            var exportedFilenmae = fileTitle + '.csv' || 'export.csv';

            var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
            if (navigator.msSaveBlob) { // IE 10+
                navigator.msSaveBlob(blob, exportedFilenmae);
            } else {
                var link = document.createElement("a");
                if (link.download !== undefined) { // feature detection
                    // Browsers that support HTML5 download attribute
                    var url = URL.createObjectURL(blob);
                    link.setAttribute("href", url);
                    link.setAttribute("download", exportedFilenmae);
                    link.style.visibility = 'hidden';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            }
        }

        var headers = {
            id: "ID",
            asin: "Asin",
            content: "Content",
        };
        var fileTitle = 'Script Bulk Export'; // or 'my-unique-title'
        exportCSVFile(headers, mockData, fileTitle); // call the exportCSVFile() function to process the JSON and trigger the download
    }

    return (
        <React.Fragment>
            <Typography variant={'h2'} mb={3}>Automated Script Card View</Typography>
            {/* <ButtonGroup
                variant="outlined"
                disableElevation
                sx={{
                    '& .MuiButton-root': {
                        px: 1,
                    }
                }}
            >
                <Button variant={view === "grid" ? "contained" : "outlined"}
                    onClick={() => setView("grid")}><ViewComfyIcon /></Button>
                <Button variant={view === "list" ? "contained" : "outlined"}
                    onClick={() => setView("table")}><ListIcon /></Button>
            </ButtonGroup> */}
            <div style={{ height: '70px', width: '100%' }}>
                <Button sx={{ float: 'right' }} variant="contained" onClick={handleExport}>
                    Export CSV
                </Button>
            </div>
            {isLoading &&
                <Div sx={{ flexGrow: 1 }}>
                    <CircularProgress color='primary' sx={{ marginLeft: '50%', marginTop: '20%' }} />
                    <Typography sx={{ marginLeft: '25%' }}>
                        <span>Getting your data...</span>
                        <span>It usually takes around 25 Seconds per script. <br /> Success <b>{counter}</b> & Failed {failedCounter} out of <b>{totalLength}</b></span>
                    </Typography>
                </Div>

            }
            <>
                {
                    isProcessed &&
                    <span>Success <b>{counter}</b> & Failed <b>{failedCounter}</b> out of {totalLength}</span>
                }
                {
                    automatedScriptsDetailData?.map((scriptData, index) =>
                        <AutomatedScriptItem scriptDetail={scriptData} key={index} />
                    )
                }
                {/* {
                        view === "grid" ? (
                            automatedScriptsDetailData.map((asin, index) =>
                                <AutomatedScriptItem asinItem={asin} key={index} />
                            )) : (
                            <AsinNumberTableView asinNumberTableData={automatedScriptsDetailData} />
                        )
                    } */}
            </>

        </React.Fragment>
    );
};

export default AutomatedScriptDetailData;
