import * as yup from "yup";
// import {useMutation} from "react-query";
// import {contactService} from "../../../../../services/contact-services";
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import Div from "@jumbo/shared/Div";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import LoadingButton from "@mui/lab/LoadingButton";
import { FormControl, InputLabel, MenuItem, Select, TextField, FormHelperText } from '@mui/material';
import subTaskService from 'app/services/subtask_service';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import React from "react";
import { useEffect, useState } from 'react';
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import moment from "moment";

const VendorsForm = ({ vendorId }) => {
    const Swal = useSwalWrapper();
    const { hideDialog } = useJumboDialog();
    const { setAuthToken } = useJumboAuth();
    const [vendorData, setVendorData] = React.useState({});
    const [selectedValue, setSelectedValue] = useState('');
    const [otherValue, setOtherValue] = useState('');
    const sweetAlerts = variant => {
        //console.log("variant", variant);
        Swal.fire({
            icon: variant,
            title: variant,
            text: variant === "success" ? 'Vendor Entered Successfully!' : "Something went wrong",
        }).then(result => {
            window.location.reload();
        });
    };

    React.useEffect(() => {
        //console.log("edit api call", vendorId);

        if (vendorId !== undefined) {
            // //console.log("vendorId edit api call");
            subTaskService.getVendorDataById(vendorId).then((data) => {
                // //console.log("data---", data);
                setVendorData(data?.data?.data)
            }).catch((err) => {
                if (err?.response?.status == 401) {
                    setAuthToken(null);
                }
            })
            // subTaskService.getLeaveApprovalData(offset, limit, filterString).then((data) => {
            //     setTotalCount(data?.data?.count);
            //     dispatch(employeeLeaveApprovalData(data?.data?.data))

            //   }).catch((err) => {
            //     if (err?.response?.status == 401) {
            //       setAuthToken(null);
            //     }
            //   })
        }
    }, [vendorId])
    useEffect(() => {
        // Update the selectedValue when vendorData changes
        if (vendorData && vendorData.vendor_type) {

            //console.log('if vendor data', vendorData)
            setSelectedValue(vendorData.vendor_type);
            if (vendorData.vendor_type === 'others' && vendorData.vendor_type) {
                setOtherValue(vendorData.vendor_type);
            }
        }
    }, [vendorData]);
    return (
        <Formik
            enableReinitialize
            initialValues={{
                vendorName: vendorData?.vendor_name ? vendorData?.vendor_name : '',
                companyName: vendorData?.company_name ? vendorData?.company_name : '',
                GSTNo: vendorData?.gst_no ? vendorData?.gst_no : '',
                billingDetails: vendorData?.billing_details ? vendorData?.billing_details : '',
                invoiceNumber: vendorData?.invoice_number ? vendorData?.invoice_number : '',
                vendorTypeId: vendorData?.vendor_type ? vendorData?.vendor_type : '',

            }}
            // initialValues={contact?.id ? contact : initialValues}
            validationSchema={yup.object().shape({
                vendorName: yup.string().required('Vendor name is required'),
                vendorTypeId: yup.string().required('Vendor Type is required'),
                companyName: yup.string().required('Company Name is required'),
                GSTNo: yup.string()
                    .matches(
                        /^[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[1-9A-Za-z]{1}[Zz]{1}[0-9A-Za-z]{1}$/,
                        'Please enter a valid GST number'
                    ),
                billingDetails: yup.string().required('Billing details is required'),
                // invoiceNumber: yup.string().required('Invoice No. is required')

            })}
            onSubmit={(values) => {
                // //console.log("onSubmit:", values);
                let payload = {
                    vendor_name: values?.vendorName,
                    company_name: values?.companyName,
                    gst_no: values?.GSTNo,
                    billing_details: values?.billingDetails,
                    invoice_number: values?.invoiceNumber,
                    vendor_type: values?.vendorTypeId


                }
                //console.log("onSubmit payload:", vendorId);
                if (vendorId !== undefined) {
                    //console.log('id---->', vendorId)
                    subTaskService.updateVendor(payload, vendorId).then((data) => {
                        hideDialog()
                        sweetAlerts('success')
                    }).catch((err) => {
                        hideDialog()
                        if (err?.response?.status == 401) {
                            sweetAlerts('error')
                        }
                    })
                } else {
                    subTaskService.AddVendorPostApi(payload).then((data) => {
                        hideDialog()
                        sweetAlerts('success')
                    }).catch((err) => {
                        hideDialog()
                        if (err?.response?.status == 401) {
                            sweetAlerts('error')
                        }
                    })
                }
                sweetAlerts('success')

            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                // isSubmitting,
                touched,
                values
            }) => {
                //console.log('errors--', errors);
                const handleVendorChange = (event) => {
                    const newValue = event.target.value;
                    // setSelectedValue(newValue);
                    setFieldValue('vendorTypeId', event?.target?.value);
                    // If 'others' is selected, clear the otherValue state
                    if (event?.target?.value !== 'others') {
                        setFieldValue('');
                    }
                };

                const handleOtherInputChange = (event) => {
                    setFieldValue(event.target.value);
                };


                // //console.log('values------', values);
                return (

                    <Form noValidate autoComplete="off">
                        <Div
                            sx={{
                                '& .MuiTextField-root': {
                                    mb: 3
                                },
                            }}
                        >
                            <FormControl sx={{ my: 2, width: "-webkit-fill-available" }}>
                                <TextField
                                    fullWidth
                                    type='text'
                                    value={values?.vendorName}
                                    // value={cardItem.child_card_title}
                                    placeholder="enter vendor name.."
                                    label="Vendor Name"
                                    variant="outlined"
                                    name="vendorName"
                                    // className="pb-0 mb-0"
                                    onChange={handleChange}
                                    style={{ marginBottom: '2px' }}
                                // sx={{ mb: 1 }}
                                />
                                <ErrorMessage name="vendorName" component="div" style={{ color: 'red' }} />
                            </FormControl>
                            <FormControl sx={{ mt: 1, mb: 3 }} fullWidth>
                                <InputLabel id="demo-simple-select-label" >Vendor Type</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Vendor Type"
                                    name='vendorTypeId'
                                    value={values?.vendorTypeId}
                                    onChange={(e) => {
                                        setFieldValue('vendorTypeId', e?.target?.value);
                                    }}
                                // onChange={handleChange}

                                >
                                    {/* <MenuItem value="option"> Select option</MenuItem> */}
                                    <MenuItem value={'freelancer'}>Freelancer</MenuItem>
                                    <MenuItem value={'rent'}>Rent</MenuItem>
                                    <MenuItem value={'vendor'}>Vendor</MenuItem>
                                    <MenuItem value={'third_party_service'}>Third Party Service</MenuItem>
                                    <MenuItem value={'utility_bills'}>Utility & Bills</MenuItem>
                                    <MenuItem value={'maintenance'}>Maintenance</MenuItem>
                                    <MenuItem value={'others'}>Others</MenuItem>

                                </Select>
                                {selectedValue === 'others' && (
                                    <TextField
                                        label="Specify Vendor Type"
                                        value={otherValue}
                                        onChange={handleOtherInputChange}
                                        fullWidth
                                    />
                                )}
                                <ErrorMessage name="vendorTypeId" component="div" style={{ color: 'red' }} />
                            </FormControl>
                            {/* <FormControl sx={{ mt: 1, mb: 3 }} fullWidth>
                                <InputLabel id="demo-simple-select-label">Vendor Type</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Vendor Type"
                                    name='vendorTypeId'
                                    value={selectedValue}
                                    onChange={handleVendorChange}
                                    error={!!errors.vendorTypeId && touched.vendorTypeId}
                                >
                                    <MenuItem value={'freelancer'}>Freelancer</MenuItem>
                                    <MenuItem value={'rent'}>Rent</MenuItem>
                                    <MenuItem value={'vendor'}>Vendor</MenuItem>
                                    <MenuItem value={'third_party_service'}>Third Party Service</MenuItem>
                                    <MenuItem value={'utility_bills'}>Utility & Bills</MenuItem>
                                    <MenuItem value={'maintenance'}>Maintenance</MenuItem>
                                    <MenuItem value={'others'}>Others</MenuItem>
                                </Select>
                                {errors.vendorTypeId && touched.vendorTypeId && (
                                    <FormHelperText error>{errors.vendorTypeId}</FormHelperText>
                                )}
                                {selectedValue === 'others' && (
                                    <TextField
                                        label="Specify Vendor Type"
                                        value={otherValue}
                                        onChange={handleOtherInputChange}
                                        fullWidth
                                    />
                                )}
                            </FormControl> */}

                            <FormControl sx={{ my: 0, width: "-webkit-fill-available" }}>
                                <TextField
                                    fullWidth
                                    type='text'
                                    value={values?.companyName}
                                    // value={cardItem.child_card_title}
                                    placeholder="enter company name"
                                    label="Company Name"
                                    variant="outlined"
                                    name="companyName"
                                    style={{ marginBottom: '2px' }}
                                    onChange={handleChange}
                                />
                                <ErrorMessage name="companyName" component="div" style={{ color: 'red' }} />
                            </FormControl>
                            {/* <FormControl sx={{ width: "-webkit-fill-available" }}>
                                <TextField
                                    id="startDate"
                                    label="Start Date"
                                    type="date"
                                    // sx={{ width: 220 }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={handleChange}
                                    value={values?.startDate}
                                />
                            </FormControl> */}
                            {/* <FormControl sx={{ width: "-webkit-fill-available" }}>
                                <TextField
                                    id="endDate"
                                    label="End Date"
                                    type="date"
                                    // sx={{ width: 220 }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={handleChange}
                                    value={values?.endDate}
                                />
                            </FormControl> */}
                            {/* <FormControl sx={{ mb: 2, width: "-webkit-fill-available" }}>
                                <InputLabel htmlFor="max-width">Time</InputLabel>
                                <Select
                                    autoFocus
                                    value={values?.time}
                                    onChange={(e) => {
                                        setFieldValue('time', e?.target?.value);
                                    }}
                                    // onChange={(event) => setMaxWidth(event.target.value)}
                                    label="maxWidth"
                                    inputProps={{
                                        name: 'max-width',
                                        id: 'max-width',
                                    }}
                                >
                                    <MenuItem value="option"> Select option</MenuItem>
                                    <MenuItem value="1st Half"> 1st Half </MenuItem>
                                    <MenuItem value="2nd Half">2nd Half </MenuItem>
                                </Select> */}
                            {/* <TextField
                                    id="time"
                                    label="Time"
                                    type="time"
                                    // sx={{ width: 220 }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={handleChange}
                                    value={values?.time}
                                // onChange={dateChange}
                                /> */}
                            {/* </FormControl> */}
                            <FormControl sx={{ my: 2, width: "-webkit-fill-available" }}>
                                <TextField
                                    fullWidth
                                    type='text'
                                    value={values?.GSTNo}
                                    // value={cardItem.child_card_title}
                                    placeholder="enter gst number"
                                    label="GST No."
                                    variant="outlined"
                                    name="GSTNo"
                                    style={{ marginBottom: '2px' }}
                                    onChange={handleChange}
                                />
                                <ErrorMessage name="GSTNo" component="div" style={{ color: 'red' }} />
                            </FormControl>
                            <FormControl sx={{ my: 1, width: "-webkit-fill-available" }}>
                                <TextField
                                    fullWidth
                                    type='text'
                                    value={values?.billingDetails}
                                    // value={cardItem.child_card_title}
                                    placeholder="enter billing details"
                                    label="Billing Details"
                                    variant="outlined"
                                    name="billingDetails"
                                    multiline
                                    onChange={handleChange}
                                    style={{ marginBottom: '2px' }}

                                />

                                <ErrorMessage name="billingDetails" component="div" style={{ color: 'red' }} />
                            </FormControl>
                            {/* <TextField
                                fullWidth
                                type='text'
                                value={values?.invoiceNumber}
                                // value={cardItem.child_card_title}
                                placeholder="enter invoice number"
                                label="Invoice Number"
                                variant="outlined"
                                name="invoiceNumber"
                                multiline
                                onChange={handleChange}
                            /> */}
                            {/* <ErrorMessage name="invoiceNumber" component="div" /> */}
                            <LoadingButton
                                fullWidth
                                type="submit"
                                variant="contained"
                                size="large"
                                sx={{ mb: 3 }}

                            // loading={isSubmitting || saveMutation.isLoading}
                            >Save</LoadingButton>
                        </Div>
                    </Form>
                );
            }}
        </Formik>
    );
};
VendorsForm.defaultProps = {
    onSave: () => {
    }
};
export default VendorsForm;
