import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import { Box, Card, CardContent, CardHeader, FormControl, InputLabel, List, ListItem, MenuItem, OutlinedInput, Select, Typography } from "@mui/material";
import subTaskService from "app/services/subtask_service";
import React, { useEffect } from 'react';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
const AdvanceFeatureBox = ({ boxId, boxInfo, setBoxTakeDetails, sx, wrapperSx, title, value }) => {
    const [allUserByRole, setAllUserByRole] = React.useState([]);
    const { setAuthToken } = useJumboAuth();
    useEffect(() => {
        onRoleSelect(boxInfo?.type_of_role)
    }, [boxInfo])
    const handleChange = (value) => {
        setBoxTakeDetails(boxId, boxInfo?.out_field_key, boxInfo?.is_multiple, value)
    }

    const handleMultipleChange = (event) => {
        const {
            target: { value },
        } = event;
        setBoxTakeDetails(boxId, boxInfo?.out_field_key, boxInfo?.is_multiple, typeof value === 'string' ? value.split(',') : value)
    };
    const onRoleSelect = async (selectedRoleData) => {
        let userDataResponse = {};
        subTaskService.getUserByRole(selectedRoleData).then((data) => {
            setAllUserByRole(data?.data?.data);
            // dispatch(subtaskStudioDetails(data?.data?.data?.data?.custom_list))
        }).catch((err) => {
            if (err?.response?.status == 401) {
                setAuthToken(null);
            }
        })
    }
    // //console.log("allUserByRole--", allUserByRole);
    return (
        <React.Fragment>
            <Card
                sx={sx}
            >
                <CardHeader
                    title={
                        title && (
                            <Typography variant={"h4"} mb={0}>{title}</Typography>
                        )
                    }
                />
                <CardContent
                    sx={{
                        display: 'flex',
                        minWidth: 0,
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: theme => theme.palette.action.hover,
                        ...wrapperSx,
                    }}
                >
                    <List
                        sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                        <ListItem>
                            <Box>
                                <FormControl sx={{ minWidth: '220px' }}>
                                    <InputLabel id="demo-simple-select-label">{title}</InputLabel>
                                    {(boxInfo?.is_multiple) ? (
                                        <Select
                                            autoFocus
                                            labelId="demo-multiple-name-label"
                                            id="demo-multiple-name"
                                            multiple
                                            value={value}
                                            onChange={handleMultipleChange}
                                            input={<OutlinedInput label="Name" />}
                                            MenuProps={MenuProps}
                                        >
                                            {allUserByRole?.map(itm => {
                                                return (<MenuItem value={itm?.name}>{itm?.name}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                    ) : (
                                        <Select
                                            autoFocus
                                            value={value}
                                            onChange={(e) => handleChange(e?.target?.value)}
                                            label="maxWidth"
                                            inputProps={{
                                                name: 'max-width',
                                                id: 'max-width',
                                            }}
                                        >
                                            {allUserByRole?.map(itm => {
                                                return (<MenuItem value={itm?.name}>{itm?.name}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                    )}
                                </FormControl>
                            </Box>
                        </ListItem>
                    </List>
                </CardContent>
            </Card>

        </React.Fragment>


    );
};


export default AdvanceFeatureBox;









