import * as yup from "yup";
import React, { useEffect, useState } from 'react';
// import {useMutation} from "react-query";
// import {contactService} from "../../../../../services/contact-services";
import JumboDemoCard from "@jumbo/components/JumboDemoCard/JumboDemoCard";
import Div from "@jumbo/shared/Div";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import LoadingButton from "@mui/lab/LoadingButton";
import { FormControl, TextField, Typography, InputLabel, OutlinedInput, Select } from '@mui/material';
import { Form, Formik } from "formik";
import DndWrapper from "./DndWrapper";
import { useDropzone } from "react-dropzone";
import subTaskService from "app/services/subtask_service";
import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import { hraxios } from "app/services/config";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import MenuItem from "@mui/material/MenuItem";
import { getProjectManagerName, ProjectManagerNameCleanUp } from 'app/redux/actions/subTaskAction';
import { useDispatch, useSelector } from 'react-redux';
import moment from "moment";

const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16,
};

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box',
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden',
};

const img = {
    display: 'block',
    width: 'auto',
    height: '100%',
};
const InfluencerAppMappingForm = ({ influencerDataId }) => {
    const Swal = useSwalWrapper();
    const [files, setFiles] = useState([]);
    const [expenseDoc, setExpenseDoc] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [influencerData, setInfluencerData] = useState({});
    const { hideDialog } = useJumboDialog();
    const { setAuthToken } = useJumboAuth();
    const [age, setAge] = useState(" ");
    const [managerOptions, setManagerOptions] = useState([]);
    const [projects, setProjects] = useState([]);
    const [selectedManager, setSelectedManager] = useState(null);
    const [selectedProject, setSelectedProject] = useState(null); // State for selected project
    const { projectManagerNameData } = useSelector(state => state.typereducer);
    //console.log("projectManagerNameData==>", projectManagerNameData);
    const [selectedVendorId, setSelectedVendorId] = useState('');
    const dispatch = useDispatch();
    const [queryOptions, setQueryOptions] = useState({});
    const [offset, setOffset] = useState(0);
    const limit = 30;
    const [totalCount, setTotalCount] = useState(0);
    const [rows, setRows] = useState([]);
    useEffect(() => {
        const rowData = projectManagerNameData.map((item) => {
            //console.log('item==>', item)
            return (
                {

                    programManagerId: item?.id,
                    programManagerName: item?.name,

                }
            )
        })
        setRows(rowData)
    }, [influencerDataId])
    // //console.log('selectedVendorId==>', selectedVendorId);
    // const rows = vendorNameData.map((item) => {
    //     //console.log('item',item)
    //     return (
    //       {

    //         vendorId: item?.id,
    //         vendorName: item?.vendor_name,

    //       }
    //     )
    //   })
    // //console.log('Mapped Rows:', rows);

    const handleVendorChange = (event) => {
        setSelectedVendorId(event.target.value); // Update the selected vendor ID state
    };

    var filterString;
    useEffect(() => {
        if (queryOptions?.filterModel?.items?.[0]?.columnField != undefined) {

            filterString = `&${queryOptions?.filterModel?.items?.[0]?.columnField}=${(queryOptions?.filterModel?.items?.[0]?.value) ? queryOptions?.filterModel?.items?.[0]?.value : ''}`

        } else {
            filterString = ''
        }
        //console.log('api call1')
        subTaskService.getProjectManagerName(offset, limit, filterString).then((data) => {
            const formattedData = data.map(manager => ({
                value: manager.id,
                label: manager.name
            }));
            //console.log('formattedData', formattedData)
            setManagerOptions(formattedData);
            setTotalCount(data?.data?.count);

        }).catch((err) => {
            if (err?.response?.status == 401) {
                setAuthToken(null);
            }
        })
        //console.log('api call3')
        subTaskService.getProject(offset, limit, filterString).then((data) => {
            //console.log('get projects')
            //console.log('data projects', data)
            const formattedProjects = data.map(project => ({
                label: project.name,
                value: project._id
              }));
              setProjects(formattedProjects);
              setTotalCount(data?.data?.count);
            
           

        }).catch((err) => {
            if (err?.response?.status == 401) {
                setAuthToken(null);
            }
        })
        return () => {
            dispatch(ProjectManagerNameCleanUp())
        }
        
    }, [offset, queryOptions, rows, influencerData, influencerDataId])

    // const { getRootProps, getInputProps } = useDropzone({
    //     accept: 'image/*,application/pdf',
    //     onDrop: acceptedFiles => {
    //         //console.log("onDrop files-");
    //         onFileUploadInput(acceptedFiles)
    //         setFiles(
    //             acceptedFiles.map(file =>
    //                 Object.assign(file, {
    //                     preview: URL.createObjectURL(file),
    //                 }),
    //             ),
    //         );
    //     },
    // });
    useEffect(() => {
        if (influencerDataId !== undefined) {
            // //console.log("influencerDataId edit api call");
            subTaskService.getInfluencerDataById(influencerDataId).then((data) => {
                setInfluencerData(data?.data?.data)
                setExpenseDoc(data?.data?.data?.bill_reciept_documents)
            }).catch((err) => {
                if (err?.response?.status == 401) {
                    setAuthToken(null);
                }
            })
        }
    }, [influencerDataId])
    useEffect(() => {
        if (influencerData) {
            const selectedManager = managerOptions.find(manager => manager.value === influencerData.managerId);
            const selectedProject = projects.find(project => project.value === influencerData.projectId);
    
            if (selectedManager) {
                setSelectedManager(selectedManager.value);
            }
            if (selectedProject) {
                setSelectedProject(selectedProject.value);
            }
        }
    }, [influencerData, managerOptions, projects]);
    const thumbs = files.map(file => (
        <div style={thumb} key={file.name}>
            <div style={thumbInner}>
                <img src={file.preview} style={img} alt="" />
            </div>
        </div>
    ));
    // const onFileUploadInput = async (file) => {
    //     // const file = document.getElementById("fileuploadinput-").files;
    //     const fileLength = file.length;
    //     let allDoc = []
    //     setIsLoading(true)
    //     if (fileLength > 0) {
    //         for (let i = 0; i < fileLength; i++) {
    //             let form_Data = new FormData();
    //             form_Data.append("attachment", file[i]);
    //             const response = await hraxios.post(`operational_expenses/expenses-upload`, form_Data);
    //             // const response = await ajax(CONSTANTS.API_METHODS.POST, CONSTANTS.API.SUB_TASK_VIDEO_UPLOAD, {}, {}, form_Data);
    //             if (response.status === 200) {
    //                 // //console.log("response---", response);
    //                 let newDoc = {
    //                     key: response?.data?.data?.id?.key,
    //                     base_url: response?.data?.data?.id?.base_url,
    //                 }
    //                 allDoc.push(newDoc);
    //                 setExpenseDoc(allDoc)
    //             } else {
    //                 sweetAlerts('error')
    //             }
    //         }
    //         setIsLoading(false)
    //     } else {
    //         setIsLoading(false)
    //         sweetAlerts('error')
    //     }
    // };
    const sweetAlerts = variant => {
        Swal.fire({
            icon: variant,
            title: variant,
            text: variant === "success" ? 'Programs Entered Successfully!' : "Something went wrong",
        }).then(result => {
            window.location.reload();
        });
    };
  
    const handleManagerChange = (event) => {
        const selectedManagerId = event.target.value;
        const manager = managerOptions.find(manager => manager.value === selectedManagerId);
        setSelectedManager(manager); // Assuming 'setSelectedManager' is the state updater
    };
    const handleProjectChange = (event) => {
        const selectedProjectId = event.target.value;
        const project = projects.find(project => project.value === selectedProjectId);
        setSelectedProject(project); // Assuming 'setSelectedManager' is the state updater
    };
    //console.log('influencer data1',influencerData)
    return (
        <Formik
            enableReinitialize
            initialValues={{
                projectId: influencerData?.project_id ? influencerData?.project_id : null,
              
                managerId: influencerData?.project_manager_id ? influencerData?.project_manager_id : null,


            }}
            validationSchema={yup.object().shape({
            })}
            onSubmit={(values) => {
                //console.log("onSubmit:", values);
                const selectedManager = managerOptions.find(manager => manager.value === values?.managerId);
                const selectedProject = projects.find(project => project.value === values?.projectId);
                let payload = {
                    program_manager_id: values?.managerId,
                    program_manager_name: selectedManager ? selectedManager.label : '',
                    program_id: values?.projectId,
                    program: selectedProject ? selectedProject.label : '',

                    // bill_reciept_documents: {
                    //     key: files,
                    //     base_url: files
                    // },
                }
                if (influencerDataId !== undefined) {
                    subTaskService.updateInfluencerData(payload, influencerDataId).then((data) => {
                        hideDialog()
                        sweetAlerts('success')
                    }).catch((err) => {
                        hideDialog()
                        if (err?.response?.status == 401) {
                            sweetAlerts('error')
                        }
                    })
                } else {
                    subTaskService.addInfluencerAppMappingPostApi(payload).then((data) => {
                        hideDialog()
                        sweetAlerts('success')
                    }).catch((err) => {
                        hideDialog()
                        if (err?.response?.status == 401) {
                            sweetAlerts('error')
                        }
                    })
                    sweetAlerts('success')
                }
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                // isSubmitting,
                touched,
                values
            }) => {
                return (
                    <Form noValidate autoComplete="off">
                        <Div
                            sx={{
                                '& .MuiTextField-root': {
                                    mb: 3
                                },
                            }}
                        >

                            <FormControl sx={{ mt: 1, mb: 3 }} fullWidth>
                                <InputLabel id="demo-simple-select-label" >Program Manager</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Program Manager"
                                    name='managerId'
                                    value={values?.managerId}
                                    onChange={(e) => {
                                        setFieldValue('managerId', e?.target?.value);
                                    }}
                                >
                                    {managerOptions.map((manager, index) => (
                                        <MenuItem key={index} value={manager.value}>
                                            {manager.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl sx={{ mt: 1, mb: 3 }} fullWidth>
                                <InputLabel id="demo-simple-select-label" >Program</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Program"
                                    name='projectId'
                                    value={values?.projectId}
                                    onChange={(e) => {
                                        setFieldValue('projectId', e?.target?.value);
                                    }}
                                >
                                    {projects.map((project, index) => (
                                        <MenuItem key={index} value={project.value}>
                                            {project.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            {/* <FormControl sx={{ mt: 1, width: "-webkit-fill-available" }}>
                                <TextField
                                    fullWidth
                                    type='text'
                                    value={values?.expenseDescription}
                                    placeholder="Description.."
                                    label="Description"
                                    variant="outlined"
                                    name="expenseDescription"
                                    multiline
                                    onChange={handleChange}
                                />
                            </FormControl>
                            <FormControl sx={{ mt: 1, width: "-webkit-fill-available" }}>
                                <TextField
                                    fullWidth
                                    type='text'
                                    value={values?.expenseNote}
                                    placeholder="Note.."
                                    label="Note"
                                    variant="outlined"
                                    name="expenseNote"
                                    multiline
                                    onChange={handleChange}
                                />
                            </FormControl> */}

                            {/* <JumboDemoCard
                                title={"Dropzone with preview"}
                            // demoCode={code}
                            >
                                <Div>
                                    <DndWrapper>
                                        <div {...getRootProps({ className: 'dropzone' })}>
                                            <input {...getInputProps()} />
                                            <Typography variant={"body1"}>Drag 'n' drop some files here, or click to select
                                                files</Typography>
                                        </div>
                                    </DndWrapper>
                                    <aside style={thumbsContainer}>{thumbs}</aside>
                                    {expenseDoc.map((doc, index) =>
                                        <a href={"https://d20vmeuwl3jwbk.cloudfront.net/" + doc?.base_url + "/" + doc?.key} target="_blank"><Typography variant={"h6"} mb={.5}>{index + 1}. {doc?.key}</Typography></a>

                                        // <aside style={thumbsContainer}>{doc}</aside>
                                    )}
                                </Div>
                            </JumboDemoCard> */}
                            {
                                isLoading ?
                                    <LoadingButton
                                        fullWidth
                                        type="submit"
                                        variant="contained"
                                        size="large"
                                        disabled={true}
                                        sx={{ mb: 3 }}
                                    // loading={isSubmitting || saveMutation.isLoading}
                                    >Save</LoadingButton> :
                                    <LoadingButton
                                        fullWidth
                                        type="submit"
                                        variant="contained"
                                        size="large"
                                        sx={{ mb: 3 }}
                                    // loading={isSubmitting || saveMutation.isLoading}
                                    >Save</LoadingButton>
                            }
                        </Div>
                    </Form>

                );
            }}
        </Formik>
    );
};
InfluencerAppMappingForm.defaultProps = {
    onSave: () => {
    }
};
export default InfluencerAppMappingForm;
