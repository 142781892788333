import React from 'react';
import Dropzone, { useDropzone } from "react-dropzone";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import { ListItem } from "@mui/material";
import JumboDemoCard from "@jumbo/components/JumboDemoCard";
import Papa from "papaparse";
// import code from "../components/demo-code/dz-basic.txt";
import Div from "@jumbo/shared/Div";
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';

export default function SimpleUploadDialog({ onChangeValue, fileHeaders }) {
    const Swal = useSwalWrapper();
    const { hideDialog } = useJumboDialog();
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        multiple: false,
        accept: '.png',
        noClick: true,
        noKeyboard: true,
    });

    const files = acceptedFiles.map(file => (
        <ListItem key={file.path} sx={{ width: 'auto', mr: 1 }}>
            {file.path} - {file.size} bytes
        </ListItem>
    ));

    const handleError = (message) => {
        hideDialog();
        const Toast = Swal.mixin({
            toast: true,
            position: 'top',
            showConfirmButton: false,
            // timer: 5000,
            timerProgressBar: true,
            onOpen: toast => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
            },
        });
        Toast.fire({
            icon: "error",
            title: message,
        });
    }
    const onChangeHandler = (acceptedFiles) => {
        // Check if headers are present
        if (acceptedFiles.length <= 0) {
            handleError("You can only upload .csv files.");
        } else {
            Papa.parse(acceptedFiles[0], {
                header: true,
                skipEmptyLines: true,
                complete: function (results) {
                    const rowsArray = [];
                    const valuesArray = [];

                    // Iterating data to get column name and their values
                    results.data.map((d) => {
                        rowsArray.push(Object.keys(d));
                        valuesArray.push(Object.values(d));
                    });

                    // var headers = rowsArray[0]; // Assuming headers are in the first row
                    // // var requiredHeaders = fileHeaders;
                    // var requiredHeaders = ['id', 'productImages', 'productName', 'backgroundImages', 'apiKey'];

                    // var areHeadersPresent = requiredHeaders.every(function (header) {
                    //     return headers.includes(header);
                    // });

                    // // Check if headers are matching
                    // var areHeadersMatching = headers.length === requiredHeaders.length &&
                    //     headers.every(function (header, index) {
                    //         return header === requiredHeaders[index];
                    //     });
                    // if (areHeadersPresent === false) {
                    //     handleError('Headers not present. Required headers are id,productImages, productName, backgroundImages, apiKey!');
                    // } else if (areHeadersMatching === false) {
                    //     handleError('Invalid header names. Required headers are id,productImages, productName, backgroundImages, apiKey!');
                    // } else {
                    //     onChangeValue(results.data)
                    // }
                    onChangeValue(results.data)
                },
            });
        }
    }
    return (
        <JumboDemoCard wrapperSx={{ pt: 0, backgroundColor: 'background.paper' }}>
            <Div sx={{ flex: 1 }}>
                <Dropzone
                    multiple={false}
                    minSize={0}
                    maxSize={10485760}
                    // accept="image/png,image/jpg,image/jpeg"
                    accept=".csv"
                    onDrop={acceptedFiles => onChangeHandler(acceptedFiles)}>
                    {/* onDrop={acceptedFiles => //console.log(acceptedFiles)}> */}
                    {({ getRootProps, getInputProps }) => (
                        <section>
                            <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <p>Drag 'n' drop some files here, or click to select files</p>
                            </div>
                        </section>
                    )}
                </Dropzone>
                {/* <div
                    className="file-select"
                    onDragOver={dragOver}
                    onDragEnter={dragEnter}
                    onDragLeave={dragLeave}
                    onDrop={fileDrop}
                    onClick={fileInputClicked}
                >
                    <h4>Select File</h4>
                    <input
                        ref={fileInputRef}
                        className="file-input"
                        type="file"
                        multiple
                        onChange={filesSelected}
                    />
                </div> */}
                <Typography variant={"h4"}>Files</Typography>
                <List disablePadding sx={{ display: 'flex' }}>
                    {files}
                </List>
            </Div>
        </JumboDemoCard>
    );
}
